/**
 *
 * @param key
 * @param data
 */
export const setSessionStorage = (key, data) => {
    let dataType = typeof data;
    if (dataType === 'object') {
        window.sessionStorage.setItem(key, JSON.stringify(data));
    } else if (['number', 'string', 'boolean'].indexOf(dataType) >= 0) {
        window.sessionStorage.setItem(key, data);
    }
}

/**
 *
 * @param key
 * @returns {string}
 */
export const getSessionStorage = (key) => {
    let data = window.sessionStorage.getItem(key)
    if (data) {
        return data;
    } else {
        return '';
    }
}

/**
 *
 * @param key
 */
export const removeSessionStorage = (key) => {
    window.sessionStorage.removeItem(key);
}

/**
 * 格式化时间， 默认YYYYMMDD
 */
//日期格式化
export const formatDate = (time, format) => {
    let date = time ? new Date(time) : new Date()
    format = format || 'yyyyMMdd'
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()
    month = month < 10 ? '0' + month : month
    day = day < 10 ? '0' + day : day

    if (format === 'yyyy-MM-dd') {
        return `${year}-${month}-${day}`
    } else if (format === 'yyyy-MM-dd hh:mm:ss') {
        let hour = date.getHours()
        let minute = date.getMinutes()
        let second = date.getSeconds()
        hour = hour < 10 ? '0' + hour : hour
        minute = minute < 10 ? '0' + minute : minute
        second = second < 10 ? '0' + second : second
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`
    } else {
        return `${year}${month}${day}`
    }
}

export const isValidPhone = (phone) => {
    let reg = /^(?:13\d|14\d|15\d|16\d|17\d|18\d|19\d)\d{5}(\d{3}|\*{3})$/
    return reg.test(phone);
}

export const constructMsg = (receiver, msg) => {
    let userInfoStr = getSessionStorage("userInfo");
    userInfoStr = userInfoStr ? userInfoStr : "{}";
    let userInfo = JSON.parse(userInfoStr);
    let username = userInfo.username;
    return {
        "sender": username,
        "receiver": receiver,
        "content": msg
    }
}

export const time2ReadableString = (ymsTime) => {
    return ymsTime;
}

export const generateUUID = () => {
    let d = new Date().getTime();
    if (window.performance && typeof window.performance.now === "function") {
        d += performance.now(); //use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}
