<template>
  <div class="about-wrap">
    <div class="statement">
      <div class="item">脚踏实地</div>
      <div class="item">座右铭：真正的价值并不在人生的舞台上，而在于我们扮演的角色中。</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'About',
  components: {},
  methods: {
  }
}
</script>

<style scoped>
.about-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card-wrap {
  width: 50%;
  margin: 10px;
}

.statement {
  width: 50%;
  border-left: 3px solid #F56C6C;
  padding: 20px;
  background-color: #EBEEF5;
  margin-top: 20px;
}
</style>
