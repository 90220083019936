<template>
  <div class="container">
    <CommentInput :isReplay="false" @handleAddComment="handleAddComment"></CommentInput>
    <div class="comment-list-warp" v-if="commentList.length > 0">
      <CommentList :commentDataList="commentList"></CommentList>
    </div>
    <div class="comment-empty-wrap" v-else>
      <span class="comment-empty-span-wrap">空空如也~~</span>
    </div>
  </div>
</template>

<script>
import CommentList from "@/components/comment/CommentList";
import CommentInput from "@/components/comment/CommentInput";

import {addComment, deleteComment, getCommentList} from "@/api/comment/commentApi";

export default {
  name: "Comment",
  mounted() {
    this.$EventBus.$off("handleAddReplyComment");
    this.$EventBus.$on("handleAddReplyComment", (data) => {
      this.handleAddReplyComment(data);
    });
    this.$EventBus.$off("handleDeleteComment");
    this.$EventBus.$on("handleDeleteComment", (id) => {
      this.handleDeleteComment(id);
    });
  },
  components: {CommentList, CommentInput},
  props: {
    dataId: {
      type: String,
      required: true
    }
  },
  watch: {
    dataId(newValue) {
      this.articleId = newValue;
      this.fetchData(this.getRequestData());
    }
  },
  data() {
    return {
      articleId: '',
      commentList: [],
    }
  },
  methods: {
    fetchData(requestData) {
      getCommentList(requestData)
          .then((response) => {
            if (response.code === '00000000000000') {
              this.dealData(response.data);
            }
          })
          .catch((error) => {
            console.log(error)
          })
    },
    dealData(data) {
      this.commentList = [];
      this.commentList = data.list;

      /*this.commentList = [];
      this.total = data.total;
      let list = data.list;
      list.forEach(item => {
        const tmp = {
          id: item.id,
          parentId: item.parentId,
          dataId: item.dataId,
          userName: item.userName,
          avatarUrl: item.avatarUrl,
          content: item.content,
          time: item.createdTime,
          children: item.children
        }
        this.commentList.push(tmp);
      })*/
    },
    handleAddComment(content) {
      const addData = {
        parentId: '0',
        dataId: this.articleId,
        content: content
      };
      this.addArticleComment(addData)
    },
    addArticleComment(addData) {
      addComment(addData)
          .then((response) => {
            if (response.code === '00000000000000') {
              this.$message.success("添加成功");
              this.fetchData(this.getRequestData());
            }
          })
          .catch((error) => {
            console.log(error)
          })
    },
    getRequestData() {
      return {
        dataId: this.articleId
      };
    },
    handleDeleteComment(id) {
      deleteComment(id)
          .then((response) => {
            if (response.code === '00000000000000') {
              this.$message.success("删除成功");
              this.fetchData(this.getRequestData());
            }
          })
          .catch((error) => {
            console.log(error)
          })
    },
    handleAddReplyComment(data) {
      const addData = {
        parentId: data.replyCommentId,
        dataId: this.articleId,
        content: data.commentContent
      }
      this.addArticleComment(addData);
    }
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.comment-empty-span-wrap {
  font-style: normal;
  font-size: 14px;
  color: grey;
}
</style>