import request from '@/api/request'

/**
 * 添加评论
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function addComment(data) {
    return request({
        url: '/comment/save',
        method: 'POST',
        data
    })
}

/**
 * 获取评论列表
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function getCommentList(data) {
    return request({
        url: '/comment/tree',
        method: 'POST',
        data
    })
}

/**
 * 删除评论
 * 
 * @param id
 * @returns {AxiosPromise}
 */
export function deleteComment(id) {
    return request({
        url: '/comment/delete/'+id,
        method: 'POST',
    })
}

