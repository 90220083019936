<template>
  <div class="msg-container">
    <!-- 后续开发 -->
    <div class="time"></div>

    <div v-if="msgType === 'own'" class="msg msg-right">
      <img class="img" :src="msgItem.avatarUrl" />
      <div class="message-wrapper message-wrapper-right">
        <div class="message">{{ msgItem.content }}</div>
      </div>
    </div>

    <div v-else class="msg msg-left">
      <img class="img" :src="msgItem.avatarUrl" />
      <div class="message-wrapper message-wrapper-left">
        <div class="message">{{ msgItem.content }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {getSessionStorage} from "@/util/util";

export default {
  name: 'ChatMsg',
  props: {
    msgItem: {
      type: Object, required: true
    }
  },
  data() {
    return {
      ws: null,
      msgReceived: false,
    };
  },
  computed: {
    username() {
      let userInfoStr = getSessionStorage("userInfo");
      userInfoStr = userInfoStr ? userInfoStr : "{}";
      let userInfo = JSON.parse(userInfoStr);
      return userInfo ? userInfo.username : "";
    },
    msgType() {
      let sender = this.msgItem.sender;
      if (sender === this.username) {
        return "own";
      } else {
        return "other";
      }
    },
  },
  created() {
  },
  mounted() {
  },
  methods: {}
};
</script>

<style scoped>
.msg-container {
  margin: 4px;
  padding: 4px;
}

.time {
  text-align: center;
}

.msg {
  display: flex;
  flex-direction: row;
}

.msg .message-wrapper {
  max-width: 400px;
  margin: 0 10px 0 10px;
}

.msg .message-wrapper .message {
  margin: 8px;
  word-wrap: break-word;
}

.msg .message-wrapper-left {
  background-color: #FFD700;
  border-radius: 0 5px 5px 5px;
}

.msg .message-wrapper-right {
  background-color: #AFEEEE;
  border-radius: 5px 0 5px 5px;
}

.msg .img {
  border-radius: 25px;
  height: 28px;
  width: 28px;
}

.msg-right {
  flex-direction: row-reverse;
}

.msg-left {
  flex-direction: row
}

</style>
