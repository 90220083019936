<template>
  <div class="container">
    <div class="comment-input-warp">
      <el-input
          ref="commentInput"
          type="textarea"
          maxlength="140"
          show-word-limit
          placeholder="请发表你的高见"
          v-model="commentContent">
      </el-input>
    </div>

    <div class="emoji-wrap">
      <el-popover
          trigger="click"
          placement="top">
        <EmojiPicker @handleEmojiSelect="handlePickEmoji"></EmojiPicker>
        <div @click="activePickEmoji" slot="reference">&#128512;</div>
      </el-popover>
    </div>

    <div class="comment-control-wrap">
      <el-button v-if="isReplay" type="primary" :disabled="commentContent===''"
                 @click="handleAddReplyComment">回复
      </el-button>

      <el-button v-else type="primary" :disabled="commentContent===''"
                 @click="handleAddComment">评论
      </el-button>
    </div>
  </div>
</template>

<script>
import EmojiPicker from "@/views/chat/components/EmojiPicker";

export default {
  name: "CommentInput",
  components: {EmojiPicker},
  props: {
    isReplay: {
      type:Boolean,
      required: false
    }
  },
  data() {
    return {
      commentContent: '',
    }
  },
  methods: {
    handleAddComment() {
      this.$emit("handleAddComment", this.commentContent);
    },
    handleAddReplyComment() {
      this.$emit('handleAddReplyComment', this.commentContent);
      this.commentContent = '';
    },
    activePickEmoji() {

    },
    handlePickEmoji(name) {
      this.commentContent += name;
    }
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.comment-control-wrap {
  text-align: right;
  margin-top: 4px;
  margin-bottom: 4px;
}
.emoji-wrap {
  margin: 0 0 0 4px;
}

</style>