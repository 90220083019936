<template>
  <div class="container">
    <el-card class="normal-status-wrap">
      <i class="el-icon-picture-outline" style="color:deeppink"></i>
      <span class="title-font">图片信息</span>
      <div class="status-content-wrap">
        <h1>{{ statusData.imgTotal }}</h1>
        <span class="tips-font">图片总数</span>
      </div>
    </el-card>

    <el-card class="chart-status-wrap">
      <i class="el-icon-mouse" style="color:blueviolet"></i>
      <span class="title-font">访问信息</span>
      <div id="visit-number-trend"></div>
    </el-card>
  </div>
</template>

<script>
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core';
// 引入折线图图表，图表后缀都为 Chart
import {LineChart} from 'echarts/charts';
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent
} from 'echarts/components';
// 标签自动布局，全局过渡动画等特性
import {LabelLayout, UniversalTransition} from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import {CanvasRenderer} from 'echarts/renderers';
// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LineChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer
]);

export default {
  name: "DashBoard",
  components: {},
  activated() {
  },
  data() {
    return {
      statusData: {
        imgTotal: 12,
        userTotal: 20,
        activityTotal: 35
      }
    };
  },
  methods: {},
  mounted() {
    //图表 - 访问量情况
    const visitTrend = echarts.init(
        document.getElementById('visit-number-trend'),
        null,
        {
          width: 400,
          height: 300
        }
    )
    let platformEntryOption = {
      color: ['#FFCC00', '#FF9055'], title: {
        text: '访问量趋势'
      },
      tooltip: {},
      xAxis: {
        data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
      },
      yAxis: {},
      series: [
        {
          data: ['12', '23', '32', '45', '45', '34', '32', '56', '87', '77', '66', '34'],
          type: 'line',
          smooth: true
        }
      ]
    };
    visitTrend.setOption(platformEntryOption)
  }
}
;
</script>

<style scoped>
.container {
  margin: 12px;
  display: flex;
}

.title-font {
  margin: 4px;
  font-weight: bold;
  font-size: 16px;
  color: #696969;
}

.tips-font {
  margin: 4px;
  font-size: 14px;
  color: grey
}

.normal-status-wrap {
  flex: 0 1 auto;
  width: 360px;
  height: 120px;
  margin-right: 12px;
}

.chart-status-wrap {

}

.status-content-wrap {
  height: 100%;
  text-align: center;
}

</style>

