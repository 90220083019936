import Vue from 'vue'
import App from './App.vue'
import router from "@/router";
import store from '@/store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/reset.css'
import './assets/icon/iconfont.css'
import './assets/icon/iconfont'

import {getSessionStorage} from "@/util/util";

Vue.config.productionTip = false
Vue.prototype.$store = store

Vue.use(ElementUI);

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresRole)) {
        let userInfoStr = getSessionStorage("userInfo");
        userInfoStr = userInfoStr ? userInfoStr : "{}";
        let userInfo = JSON.parse(userInfoStr);
        let userRole = userInfo ? userInfo.role : "";
        let requiresRole = to.meta.requiresRole;
        //前端路由角色权限控制
        if (!requiresRole.includes(userRole)) {
            next({
                path: '/login',
                query: {redirect: to.fullPath}
            })
        } else {
            next()
        }
    } else {
        next() // 确保一定要调用 next()
    }
})

new Vue({
    router,
    store,
    beforeCreate() {
        Vue.prototype.$EventBus = this
    },
    render: h => h(App),
}).$mount('#app')