import axios from "axios";

export function getAnswer(question) {
    const params = {
        appid: 'xiaosi',
        userid: 'user',
        spoken: question
    };
    return axios.request({
        method: 'GET',
        baseURL: '/ownThinkApi',
        url: '/bot',
        params
    });
}