<template>
  <div class="f-footer">
    <div class="main">
      <div class="item">
        contact@crkyl.cn
      </div>
      <div class="item">
        © 2021 crkyl.cn | <a style="color: #fff;font-size: 14px" target="_blank" rel="noopener noreferrer" href="https://beian.miit.gov.cn/">黔ICP备18011172号-2</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
.f-footer {
  text-align: center;
  color: #fff;
  background-color: #808080;
}

.main {
  text-align: center;
  width: 80%;
  margin: 8px auto;
  color: #ccc;
}
.item {
  color: #fff;
  font-size: 14px;
}

.item-col .title {
  color: #ddd;
  margin-bottom: 5px;
}

.item span {
  margin-right: 5px;
}
</style>