<template>
  <div class="container">
    <div class="attachment-list-warp">
      <template v-for="(item) in computedAttachmentList">
        <div class="attachment-warp" :key="item.id" @click="handleAttachmentClick(item)">
          <div class="attachment-icon-warp">
            <el-image v-if="item.type === 'img'" fit="cover" :src="item.url">
            </el-image>

            <svg v-else class="icon-font">
              <use :xlink:href="attachmentTypeSetting[item.type]"></use>
            </svg>
          </div>
          <div class="attachment-name-warp">{{ item.name }}</div>
        </div>
      </template>
    </div>

  </div>
</template>

<script>
export default {
  name: "AttachmentList",
  props: {
    attachmentList: {
      type: Array,
      required: true,
    }
  },
  computed: {
    computedAttachmentList() {
      let list = [];
      this.attachmentList.map((item) => {
        let suffix = item.url.substr(item.url.lastIndexOf(".") + 1).toLowerCase();
        if (suffix === 'jpg' || suffix === 'jpeg' || suffix === 'png') {
          item.type = "img";
          item.url = item.url + "?imageMogr2/format/webp";
        } else if (suffix === 'gif' || suffix === 'webp') {
          item.type = "img";
        } else if (suffix === "pdf") {
          item.type = "pdf";
        } else if (suffix === "doc" || suffix === "docx") {
          item.type = "word";
        } else if (suffix === "ppt" || suffix === "pptx") {
          item.type = "ppt";
        } else if (suffix === "xls" || suffix === "xlsx" || suffix === "csv") {
          item.type = "excel";
        } else if (suffix === "java" || suffix === "c" || suffix === "cpp" || suffix === "m" || suffix === "py") {
          item.type = "code";
        } else if (suffix === "zip" || suffix === "rar" || suffix === "7z" || suffix === "gz") {
          item.type = "zip";
        } else if (suffix === "mp4") {
          item.type = "video";
        } else if (suffix === "mp3") {
          item.type = "music";
        } else {
          item.type = "unknown";
        }

        list.push(item);
      })

      return list;
    }
  },
  data() {
    return {
      attachmentTypeSetting: {
        pdf: "#icon-pdf-1",
        word: "#icon-word-1",
        excel: "#icon-excel-1",
        ppt: "#icon-ppt-1",
        code: "#icon-div-1",
        zip: "#icon-zip-1",
        video: "#icon-mp4-1",
        music: "#icon-mp3-1",
        unknown: "#icon-txt-1",
      }
    }
  },
  methods: {
    handleAttachmentClick(data) {
      this.$emit("handleAttachmentClick", data);
    }
  }

}
</script>

<style scoped>
.attachment-list-warp {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%;
}

.attachment-warp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border: 1px solid lightblue;
  transition: all 0.5s;
  margin: 4px;
}

.attachment-warp:hover {
  transform: translateY(-5px);
}

.attachment-icon-warp {

}

.attachment-name-warp {

}

.icon-font {
  width: 2em;
  height: 2em;
  fill: currentColor;
  overflow: hidden;
}
</style>