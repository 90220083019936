<template>
  <div class="container">
    <el-menu style="display:flex;" type="flex">
      <el-menu-item @click.native="onLogoClick" class="mr-auto"><span>后台管理</span></el-menu-item>

      <el-menu-item style="margin-left: auto">
        <el-dropdown v-if="username" trigger="click" @command="handleCommand">
          <el-avatar :src="avatar"></el-avatar>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="logout">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button v-else type="text" @click="handleLogin">登录</el-button>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import {setSessionStorage, getSessionStorage} from "@/util/util";

export default {
  name: "ManageHeader",
  props: {},
  computed: {
    username() {
      return this.userInfo ? this.userInfo.username : "";
    },
    avatar() {
      return this.userInfo ? this.userInfo.avatar : "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg";
    }
  },
  data() {
    return {
      activeIndex: '/',
      userInfo: {},
    };
  },
  mounted() {
    let userInfoStr = getSessionStorage("userInfo");
    userInfoStr = userInfoStr ? userInfoStr : "{}";
    this.userInfo = JSON.parse(userInfoStr);
  },
  methods: {
    onLogoClick() {
      this.$router.push("/");
    },
    handleCommand(command) {
      switch (command) {
        case 'logout':
          setSessionStorage("userInfo", "{}");
          this.$router.push("/login");
          break;
        default:
          break;
      }
    },
    handleLogin() {
      this.$router.push("/login");
    }
  }
}
</script>

<style scoped>

</style>