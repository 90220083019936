<template>
  <div class="msg-emoji-container">
    <ul class="emoji-items-wrap">
      <li class="emoji-item-wrap"
          v-for="item in emojiList"
          :key="item.id"
          @click="chooseEmoji(item.value)">
        <span>{{ item.value }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'EmojiPicker',
  props: {},
  data() {
    return {
      emojiList: [
        {id: 1, value: String.fromCodePoint(0x1F600)},
        {id: 2, value: String.fromCodePoint(0x1F601)},
        {id: 3, value: String.fromCodePoint(0x1F602)},
        {id: 4, value: String.fromCodePoint(0x1F603)},
        {id: 5, value: String.fromCodePoint(0x1F604)},
        {id: 6, value: String.fromCodePoint(0x1F605)},
        {id: 7, value: String.fromCodePoint(0x1F606)},
        {id: 8, value: String.fromCodePoint(0x1F607)},
        {id: 9, value: String.fromCodePoint(0x1F608)},
        {id: 10, value: String.fromCodePoint(0x1F609)},
        {id: 11, value: String.fromCodePoint(0x1F60A)},
        {id: 12, value: String.fromCodePoint(0x1F60B)},
        {id: 13, value: String.fromCodePoint(0x1F60C)},
        {id: 14, value: String.fromCodePoint(0x1F60D)},
        {id: 15, value: String.fromCodePoint(0x1F60E)},
        {id: 16, value: String.fromCodePoint(0x1F9D0)},
        {id: 17, value: String.fromCodePoint(0x1F92A)},
        {id: 18, value: String.fromCodePoint(0x1F929)},
        {id: 19, value: String.fromCodePoint(0x1F315)},
        {id: 20, value: String.fromCodePoint(0x1F31E)},
        {id: 21, value: String.fromCodePoint(0x1F337)},
        {id: 22, value: String.fromCodePoint(0x1F339)},
        {id: 23, value: String.fromCodePoint(0x1F35A)},
        {id: 24, value: String.fromCodePoint(0x1F384)},
      ]
    };
  },
  computed: {},
  created() {
  },
  mounted() {
  },
  methods: {
    chooseEmoji(name) {
      this.$emit("handleEmojiSelect", name);
    },
  }
};
</script>

<style scoped>
.msg-emoji-container {
  display: flex;
  align-items: center;
}

.emoji-items-wrap {
  max-height: 200px;
  max-width: 200px;
  overflow-y: scroll;
  padding: 5px;
}

.emoji-item-wrap {
  background: transparent;
  border-radius: 2px;
  padding: 5px;
  display: inline-block;
  transition: 0.5s;
  line-height: 20px;
  font-size: 16px;
  cursor: pointer;
}

.emoji-item-wrap :hover {
  font-size: 17px;
}
</style>
