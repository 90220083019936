import request from "@/api/request";

export function getDailyImgList(data) {
    return request({
        url: '/dayimg/list',
        method: 'post',
        data
    })
}

export function addDailyImg(data) {
    return request({
        url: '/dayimg/save',
        method: 'post',
        data
    })
}