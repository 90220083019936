import request from '@/api/request'

/**
 * 添加文章
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function addArticle(data) {
    return request({
        url: '/article/save',
        method: 'POST',
        data
    })
}

/**
 * 获取文章列表
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function getArticleList(data) {
    return request({
        url: '/article/list',
        method: 'POST',
        data
    })
}

/**
 *
 * @param id
 * @returns {AxiosPromise}
 */
export function deleteArticle(id) {
    return request({
        url: '/article/delete/'+id,
        method: 'POST',
    })
}

/**
 *
 * @param id
 * @returns {AxiosPromise}
 */
export function getArticleDetail(id) {
    return request({
        url: '/article/detail/'+id,
        method: 'GET',
    })
}
