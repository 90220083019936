<template>
  <el-form status-icon :model="addForm" ref="addForm" label-width="100px" class="demo-ruleForm">
    <template v-for="item in formItemData">
      <el-form-item
          :key="item.id"
          :label="item.label"
          :prop="item.prop"
          :rules="rules[item.rule]">
        <div class="form-input-wrap">
          <el-input clearable v-if="item.itemType === 'input'" :type="item.type" :placeholder="item.placeholder"
                    v-model="addForm[item.model]" :show-password="item.type === 'password'"
                    autocomplete="off"></el-input>
        </div>
      </el-form-item>
    </template>

    <el-form-item>
      <el-button size="small" @click="handleCancel('addForm')">取消</el-button>
      <el-button size="small" type="primary" @click="submitForm('addForm')">提交</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import {addUser} from "@/api/user/userApi";
import Base64 from "crypto-js/enc-base64";
import sha256 from "crypto-js/sha256";

export default {
  name: 'UserAddForm',
  data() {
    //const pwdRegex = new RegExp('(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,30}');
    const validatePwd = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (value.length < 8) {
        callback(new Error('密码不少于8位'));
      } else {
        callback();
      }
    };

    const validateCheckPwd = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.addForm.pwd) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };

    return {
      addForm: {
        name: '',
        pwd: '',
      },
      rules: {
        name: [
          {required: true, message: '用户名不能为空', trigger: 'blur'},
        ],
        pwd: [
          {validator: validatePwd, required: true, trigger: 'blur'},
        ],
        checkPwd: [
          {validator: validateCheckPwd, required: true, trigger: 'blur'},
        ],
      },
      formItemData: [
        {
          id: "1",
          label: '用户名',
          prop: 'name',
          rule: 'name',
          model: 'name',
          placeholder: "请输入用户名",
          itemType: 'input',
          type: 'text'
        },
        {
          id: "2",
          label: '密码',
          prop: 'pwd',
          rule: 'pwd',
          model: 'pwd',
          placeholder: "请输入密码",
          itemType: 'input',
          type: 'password'
        },
        {
          id: "3",
          label: '重复密码',
          prop: 'checkPwd',
          rule: 'checkPwd',
          model: 'checkPwd',
          placeholder: "请再次输入密码",
          itemType: 'input',
          type: 'password'
        },
      ]
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          addUser(this.getSubmitData(this.addForm))
              .then(response => {
                if (response.code === '00000000000000') {
                  this.$emit("handleUserAdd");
                  this.$message.success("添加用户成功");
                  this.resetForm("addForm");
                  this.$router.back();
                }
              })
              .catch((error) => {
                console.log(error)
              })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleCancel(formName) {
      this.resetForm(formName);
      this.$router.back();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    getSubmitData(data) {
      let cryptPwd = Base64.stringify(sha256(data.pwd));
      return {
        userName: data.name,
        userPwd: cryptPwd,
      }
    }
  }
}
</script>
<style scoped>
.form-input-wrap {
  width: 360px;
}
</style>