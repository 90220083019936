<template>
  <div class="container">
    <el-timeline style="width: 360px">
      <el-timeline-item
          v-for="(activity, index) in activitiesShow"
          :key="index"
          :color="activity.color"
          size="large">
        <transition name="fade">
          <div class="time-line-item-card">
            <el-card :body-style="{ padding: '0px' }">
              <div style="padding: 14px;">
                <span>{{ activity.content }}</span>
                <div class="bottom clearfix">
                  <time class="time">{{ activity.timestamp }}</time>
                </div>
              </div>
            </el-card>
          </div>
        </transition>
      </el-timeline-item>
    </el-timeline>
    <div class="control-wrap">
      <el-button @click="preActivity"><i class="el-icon-arrow-left el-icon--right"></i>往前</el-button>
      <el-button @click="nextActivity">往后<i class="el-icon-arrow-right"></i></el-button>
    </div>
  </div>
</template>
<script>
import {getActivityList} from "@/api/timeline/activityApi";
import {formatDate} from "@/util/util"
export default {
  name: "Timeline",
  activated() {
    this.fetchData(this.getRequestData());
  },
  components: {},
  data() {
    return {
      pageForm: {
        pageNum: 1,
        pageSize: 100,
      },
      activitiesShow: [],
      activitiesAll: [],
      isAdmin: true,
    };
  },
  methods: {
    preActivity() {
      if (this.activitiesShow.length >= 2) {
        this.activitiesShow.pop();
      } else {
        this.$message.warning("已经是第一个啦")
      }
    },
    nextActivity() {
      if (this.activitiesAll && this.activitiesShow) {
        let total = this.activitiesAll.length;
        let current = this.activitiesShow.length;
        let index = current >= total ? total - 1 : current;
        let item = this.activitiesAll[index];
        if (current < total) {
          this.activitiesShow.push(item);
        } else {
          this.$message.warning("已经是最后一个啦")
        }
      }
    },
    handleClose() {
    },

    handleActivityAdd() {
      this.fetchData(this.getRequestData());
    },
    fetchData(requestData) {
      getActivityList(requestData)
          .then((response) => {
            if (response.code === '00000000000000') {
              this.$message.success("获取活动列表成功");
              this.dealData(response.data);
            }
          })
          .catch((error) => {
            console.log(error)
          })
    },
    dealData(data) {
      this.activitiesAll = [];
      let list = data.list;
      list.forEach(item => {
        let tmp = {
          id: item.id,
          content: item.activityName,
          timestamp: formatDate(item.activityDate,"yyyy-MM-dd"),
          desc: item.activityDesc,
          color: item.activityColor
        }
        this.activitiesAll.push(tmp);
      })
    },
    getRequestData() {
      return {
        page: this.pageForm.pageNum,
        size: this.pageForm.pageSize
      };
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.control-wrap {
  text-align: center;
}

.time-line-item-card {
  width: 100%;
  cursor: url("https://crkyl-1251696585.cos.ap-guangzhou.myqcloud.com/mm.cur"), default;
}
</style>