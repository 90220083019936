import {download} from "@/api/request";
import request from "@/api/request";

/**
 *
 * @param data
 */
export function getDailyImg(data) {
    return request({
        url: '/dayimg/get',
        method: 'post',
        data
    })
}

/**
 * 获取captcha验证码
 *
 * @param data
 * @returns {*}
 */
export function getCaptcha() {
    return download(
        '/user/captcha',
        'post',
    )
}

/**
 * 获取二维码
 *
 * @param data
 * @returns {*}
 */
export function getQRCode(data) {
    return download(
        '/user/qrcode/generate',
        'post',
        data
    )
}

/**
 * 获取二维码状态
 *
 * @param id
 * @returns {AxiosPromise}
 */
export function getQRCodeState(id) {
    return request({
        url: '/user/qrcode/state/'+id,
        method: 'GET',
    })
}


/**
 * 账号密码登录
 *
 * @param data
 * @returns {*}
 */
export function accountLogin(data) {
    return request({
        url: '/user/login',
        method: 'post',
        data
    })
}

/**
 * 获取短信验证码
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function getSms(data) {
    return request({
        url: '/user/smscode',
        method: 'post',
        data
    })
}

/**
 * 短信验证码登录
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function smsLogin(data) {
    return request({
        url: '/user/smslogin',
        method: 'post',
        data
    })
}

/**
 * 退出
 *
 * @param data
 * @returns {*}
 */
export function logout(data) {
    return request({
        url: '/user/logout',
        method: 'post',
        data
    })
}