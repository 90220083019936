<template>
  <div class="chat-container">
    <div class="chat-header-wrap">
      <ChatHeader></ChatHeader>
    </div>
    <div class="chat-msg-wrap">
      <transition-group name="list" tag="p">
        <ChatMsg
            v-for="(item,index) in msgItems"
            ref="chatMsgItems"
            :key="index"
            :msgItem="item">
        </ChatMsg>
      </transition-group>
      <!-- 用以支持新消息滑动到底部 -->
      <div ref="chatMsgItemsBottom"></div>
    </div>
    <div class="chat-input-wrap">
      <ChatInput @handleSendMsg="sendMessage">
      </ChatInput>
    </div>
  </div>
</template>

<script>
import ChatHeader from "@/views/chat/components/ChatHeader";
import ChatMsg from "@/views/chat/components/ChatMsg";
import ChatInput from "@/views/chat/components/ChatInput";
import {getSessionStorage} from "@/util/util";
//import {getChatAnswer} from "@/api/chat/chatApi";
import {getAnswer} from "@/api/thirdPart/ownThinkApi"

export default {
  components: {
    ChatHeader, ChatMsg, ChatInput,
  },
  activated() {
    this.getMessage("我无所不知");
  },
  data() {
    return {
      msgItems: [],
      avatarEva: 'https://crkyl-1251696585.cos.ap-guangzhou.myqcloud.com/302837344766001152.JPG',
      avatarLili: 'https://crkyl-1251696585.cos.ap-guangzhou.myqcloud.com/lili.jpg',
      avatarKiki: 'https://crkyl-1251696585.cos.ap-guangzhou.myqcloud.com/kiki.jpg',
      welcome: '',
      answer: ''
    };
  },
  computed: {
    username() {
      let userInfoStr = getSessionStorage("userInfo");
      userInfoStr = userInfoStr ? userInfoStr : "{}";
      let userInfo = JSON.parse(userInfoStr);
      return userInfo ? userInfo.username : "";
    },
    token() {
      return getSessionStorage("token");
    }

  },
  created() {

  },
  mounted() {

  },
  methods: {
    sendMessage(msgContent) {
      let msg = {
        sender: this.username,
        content: msgContent,
        receiver: this.username === "kangkang" ? "lili" : "kangkang",
      };
      //添加我发送的消息
      msg.avatarUrl = this.username === "kangkang" ? this.avatarKiki : this.avatarLili;
      this.msgItems.push(msg);
      //拿答案
      let that = this;

 //     that.fetchAnswer(that.getChatRequestData(msgContent));
      that.fetchAnswer(msgContent);

    },
    getMessage(msgContent) {
      let that = this;
      that.msgItems = [
        ...that.msgItems,
        {
          sender: that.username === "kangkang" ? "lili" : "kangkang",
          content: msgContent,
          avatarUrl: that.avatarEva,
        }
      ];
    },
    fetchAnswer(data) {
      getAnswer(data)
          .then((response) => {
            const responseData = response.data;
            if (responseData.message === 'success') {
              let answer = responseData.data.info.text;
              this.getMessage(answer);
            }
          })
          .catch((error) => {
            console.log(error);
          })
    },
    getChatRequestData(question) {
      return {
        question: question,
        traceId: "test-123"
      }
    }
  },
  updated() {
    let bottom = this.$refs["chatMsgItemsBottom"];
    bottom.scrollIntoView(false);
  }
}
;
</script>

<style scoped>
.chat-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 360px;
  background: white;
  box-shadow: 0 4px 10px 4px rgba(231, 221, 221, 0.75);
}

.chat-header-wrap {
  margin: 0 0 12px 0;
  width: 360px;
}

.chat-msg-wrap {
  overflow-y: scroll;
  height: 360px;
  width: 360px;
}

.chat-input-wrap {
  width: 360px;
  margin: 12px 0;
}

.list-enter-active, .list-leave-active {
  transition: all 1.5s;
}

.list-enter, .list-leave-to
  /* .list-leave-active for below version 2.1.8 */
{
  opacity: 0;
  transform: translateY(30px);
}
</style>
