<template>
  <div class="msg-input-container">
    <div class="emoji-wrap">
      <el-popover
          trigger="click"
          placement="top">
        <EmojiPicker @handleEmojiSelect="handlePickEmoji"></EmojiPicker>
        <div @click="activePickEmoji" slot="reference">&#128512;</div>
      </el-popover>
    </div>
    <div class="input-wrap">
      <el-input
          @keyup.enter.native="sendMessage"
          size="small"
          placeholder=""
          v-model="msgContent">
      </el-input>
    </div>

    <div class="send-wrap">
      <el-button
          :disabled="msgContent === ''"
          size="small"
          type="primary"
          @click="sendMessage">发送
      </el-button>
    </div>

  </div>
</template>

<script>
import EmojiPicker from "@/views/chat/components/EmojiPicker";

export default {
  name: 'ChatInput',
  components: {
    EmojiPicker
  },
  props: {},
  data() {
    return {
      msgContent: ''
    };
  },
  computed: {},
  created() {
  },
  mounted() {
  },
  methods: {
    sendMessage() {
      if (this.msgContent === '') {
        this.$message.info("消息不能为空")
      } else {
        this.$emit("handleSendMsg", this.msgContent);
      }
      this.msgContent = '';
    },
    activePickEmoji() {

    },
    handlePickEmoji(name) {
      this.msgContent += name;
    }
  }
};
</script>

<style scoped>
.msg-input-container {
  display: flex;
  align-items: center;
}

.emoji-wrap {
  margin: 0 0 0 4px;
}

.input-wrap {
  margin: 0 4px 0 4px;
  flex: 1 0 auto;
}

.send-wrap {
  margin: 0 4px 0 0;
}

</style>
