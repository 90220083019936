import request from '@/api/request'

/**
 * 添加事件
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function addActivity(data) {
    return request({
        url: '/activity/save',
        method: 'POST',
        data
    })
}

/**
 * 获取事件列表
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function getActivityList(data) {
    return request({
        url: '/activity/list',
        method: 'POST',
        data
    })
}


/**
 *
 * @param id
 * @returns {AxiosPromise}
 */
export function deleteActivity(id) {
    return request({
        url: '/activity/delete/'+id,
        method: 'POST',
    })
}

