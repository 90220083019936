<template>
  <div class="container">
    <el-dialog
        width="500px"
        title="新建相册"
        top="10vh"
        :visible.sync="dialogVisible">
      <el-form :model="formData" ref="albumAddForm">
        <el-form-item
            :prop="item.vModel"
            :key="item.vModel"
            :label="item.label"
            v-for="item in computedFormSettings">
          <el-input
              :clearable="true"
              :placeholder="item.placeholder"
              class="inputItem"
              v-if="item.type === 'textInput'"
              v-model="formData[item.vModel]"
          ></el-input>
          <el-select
              :clearable="true"
              :placeholder="item.placeholder"
              v-else-if="item.type === 'selection'"
              v-model="formData[item.vModel]">
            <el-option
                :key="item.value"
                :label="item.label"
                :value="item.value"
                v-for="item in item.options">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="info" @click="onCancel">取消</el-button>
          <el-button type="primary" @click="onSubmit">添加</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "AlbumAddDialog",
  props: {
    albumAddDialogVisible: {
      type: Boolean,
      required: true
    },
    formItemSettings: {
      type: Array,
      // required: true,
    }
  },
  computed: {
    dialogVisible: {
      get: function () {
        return this.albumAddDialogVisible;
      },
      set: function (newVal) {
        this.$emit('update:albumAddDialogVisible', newVal);
      }
    },
    computedFormSettings() {
      return this.formItemSettingsTest
    }
  },
  components: {},
  data() {
    return {
      formData: {
        name: '',
        brief: '',
        privateView: '',
        coverUrl: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      },
      formItemSettingsTest: [
        {type: "textInput", label: "相册名称", vModel: "name", placeholder: "相册名称"},
        {type: "textInput", label: "相册描述", vModel: "brief", placeholder: "说说这个相册的故事"},
        {
          type: "selection", label: "访问权限", vModel: "privateView", placeholder: "访问权限",
          options: [{label: "公开", value: "0"}, {label: "私有", value: "1"}]
        },
      ]
    }
  },
  methods: {
    onSubmit() {
      this.$emit("handleSubmit",this.formData)
      this.dialogVisible = false;
    },
    onCancel() {
      this.$refs['albumAddForm'].resetFields();
      this.dialogVisible = false;
    },
  }
}
</script>

<style scoped>
.container {

}
</style>