<template>
  <div class="container">
    <div class="table-control-wrap">
      <div class="search-input-wrap">
        <el-input
            clearable
            size="small"
            placeholder="请输入名称"
            @keyup.enter.native="handleQuery"
            v-model="requestForm.name">
        </el-input>
      </div>
      <el-button type="primary" size="small" @click="handleQuery">搜索</el-button>
      <el-button type="success" size="small" @click="handleAdd">新增</el-button>
    </div>
    <InfoTable
        :columnsInfo="columnsData"
        :tableData="InfoDataTable"
        :isLoading="isLoading"
        :total="total">
    </InfoTable>
    <div class="pagination-wrap">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageForm.pageNum"
          :page-sizes="[5, 10, 20, 50]"
          :page-size="pageForm.pageSize"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import InfoTable from "@/views/manage/components/InfoTable";
import {getDailyImgList} from "@/api/dayImg/dayImgApi";

export default {
  name: "DayImgManage",
  components: {InfoTable},
  activated() {
    this.fetchData(this.getRequestData());
  },
  data() {
    return {
      pageForm: {
        pageNum: 1,
        pageSize: 10,
      },
      requestForm: {},
      total: 0,
      InfoDataAll: [],
      InfoDataTable: [],
      isLoading: false,
      columnsData: [
        {prop: "id", label: "id", width: "150", align: "center",},
        {prop: "startDate", label: "开始日期", width: "150", align: "center"},
        {prop: "endDate", label: "结束日期", width: "150", align: "center"},
        {prop: "dayName", label: "日期名称", width: "150", align: "center",},
        {prop: "dayType", label: "日期类型", width: "150", align: "center",},
        {prop: "dayWeight", label: "日期权重", width: "150", align: "center",},
        {prop: "dayDesc", label: "日期描述", width: "150", align: "center",},
        {prop: "imgUrl", label: "图片url", width: "150", align: "center",},
        {prop: "imgCopyright", label: "图片版权信息", width: "150", align: "center",},
        {prop: "createdBy", label: "创建人ID", width: "150", align: "center",},
        {prop: "createTime", label: "创建时间", width: "200", align: "center",},
      ],
    };
  },
  methods: {
    handleSizeChange(val) {
      this.pageForm.pageNum = 1;
      this.pageForm.pageSize = val;
      this.fetchData(this.getRequestData());
    },
    handleCurrentChange(val) {
      this.pageForm.pageNum = val;
      this.fetchData(this.getRequestData());
    },
    handleQuery() {

    },
    handleAdd() {
      this.$bus.$emit("handleDayImgAdd");
    },
    fetchData(requestData) {
      this.isLoading = true;
      getDailyImgList(requestData)
          .then((response) => {
            if (response.code === '00000000000000') {
              this.$message.success("获取列表成功");
              this.dealData(response.data);
              this.isLoading = false;
            }
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          })
    },
    dealData(data) {
      this.total = data.total;
      this.InfoDataAll = [];
      let list = data.list;
      list.forEach(item => {
        let tmp = {
          id: item.id,
          startDate: item.startDate,
          endDate: item.endDate,
          dayName: item.dayName,
          dayType: item.dayType,
          dayWeight: item.dayWeight,
          dayDesc: item.dayDesc,
          imgUrl: item.imgUrl,
          imgCopyright: item.imgCopyright,
          createdBy: item.createdBy,
          createTime: item.createTime
        }
        this.InfoDataAll.push(tmp);
      });
      this.InfoDataTable = this.InfoDataAll;
    },
    getRequestData() {
      return {
        page: this.pageForm.pageNum,
        size: this.pageForm.pageSize
      };
    }
  }
}
;
</script>

<style scoped>
.container {
  background: #ffffff;
  margin: 12px;
}

.search-input-wrap {
  margin: 10px;
  display: inline-block
}

.pagination-wrap {
  padding: 10px 20px;
  text-align: right;
}
</style>

