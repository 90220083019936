import request from "@/api/request";

/**
 * 上传文件
 *
 * @param data
 * @returns {*}
 */
export function uploadFile(data) {
    return request({
        url: '/file/upload',
        method: 'post',
        data
    })
}