<template>
  <div class="container">
    <div class="album-wrap">
      <template v-for="(item,index) in albumList">
        <AlbumItem
            @handleAlbumDetail="handleAlbumDetail"
            :key="index"
            :albumItem="item">
        </AlbumItem>
      </template>
    </div>

    <AlbumAddDialog :albumAddDialogVisible.sync="albumAddDialogVisible"
                    @handleSubmit="handleAlbumAddSubmit"
    ></AlbumAddDialog>

    <div class="add-btn-wrap">
      <el-button type="primary" icon="el-icon-plus" circle @click="handleAddAlbum"></el-button>
    </div>
  </div>
</template>

<script>
import AlbumItem from "@/views/album/components/AlbumItem";
import AlbumAddDialog from "@/views/album/components/AlbumAddDialog";
import {getAlbumList, addAlbum} from "@/api/album/albumApi";

export default {
  name: 'Album',
  components: {AlbumItem, AlbumAddDialog},
  activated() {
    this.fetchData(this.getRequestData());
  },
  data() {
    return {
      pageForm: {
        pageNum: 1,
        pageSize: 100,
      },
      albumList: [],
      albumAddDialogVisible: false
    }
  },
  methods: {
    handleClose() {
    },
    handleAddAlbum() {
      this.albumAddDialogVisible = true;
    },
    handleAlbumDetail(id) {
      this.$router.push({name: 'img', params: {albumId: id}});
    },
    handleAlbumAddSubmit(data) {
      const requestData = this.getAddSubmitData(data);
      addAlbum(requestData)
          .then((response) => {
            if (response.code === '00000000000000') {
              this.$message.success("添加相册成功");

              this.fetchData(this.getRequestData());
            }
          })
          .catch((error) => {
            console.log(error)
          })

    },
    fetchData(requestData) {
      getAlbumList(requestData)
          .then((response) => {
            if (response.code === '00000000000000') {
              this.$message.success("获取相册成功");
              this.dealData(response.data);
            }
          })
          .catch((error) => {
            console.log(error)
          })
    },
    dealData(data) {
      this.albumList = [];
      let list = data.list;
      list.forEach(item => {
        let tmp = {
          id: item.id,
          name: item.nameCn,
          brief: item.brief,
          coverUrl: item.coverUrl+"?imageMogr2/format/webp",

          privateView: 0,
          imgCount: item.imgCount,
        }
        this.albumList.push(tmp);
      })
    },
    getRequestData() {
      return {
        page: this.pageForm.pageNum,
        size: this.pageForm.pageSize,
      };
    },
    getAddSubmitData(data) {
      return {
        nameCn: data.name,
        brief: data.brief,
        coverUrl: data.coverUrl,
        revision: "1"
      };
    }
  }
}
</script>

<style scoped>
.container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.album-wrap {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%;
}

.add-btn-wrap {
  position: absolute;
  margin: 64px 15px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, .32);
  bottom: 0;
  right: 0;
  z-index: 100;
  transition: all 0.5s;
}

.add-btn-wrap:hover {
  transform: translateY(-3px);
}

</style>
