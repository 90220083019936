<template>
  <div class="container">
    <el-form :model="form" status-icon label-width="100px" class="demo-ruleForm">
      <el-form-item label="私密文章" prop="privateView">
        <el-switch v-model="form.privateView"
                   active-text="开启后，文章仅自己可见">
        </el-switch>
      </el-form-item>
      <el-form-item label="封面图" prop="coverUrl">
        <div class="img-wrap" v-on:click="handleImgSelect">
          <el-image
              style="width: 100%; height: 100px"
              :src="form.coverUrl"
              fit="fit">
          </el-image>
        </div>
        <el-input type="text" placeholder="点击选择图片，或者输入外部连接" v-model="form.coverUrl"></el-input>
      </el-form-item>
    </el-form>

    <AttachmentDialog :attachmentDialogVisible.sync="attachmentDialogVisible"
                      @handleAttachmentItem="handleAttachmentItem"
    ></AttachmentDialog>

  </div>
</template>

<script>
import AttachmentDialog from "@/components/attachment";

export default {
  name: "ArticleMoreInfoForm",
  components: {AttachmentDialog},
  data() {
    return {
      attachmentDialogVisible: false,
      form: {
        privateView: false,
        coverUrl: ""
      },
    }
  },
  methods: {
    handleImgSelect() {
      this.attachmentDialogVisible = true;
    },
    handleAttachmentItem(data) {
      if (data.type === "img") {
        this.form.coverUrl = data.url;
      } else {
        this.$message.info("不是图片类型");
      }
    }
  }
}
</script>

<style scoped>
.container {
  margin: unset;
  padding: unset;
}

.img-wrap {
  width: 100px;
  cursor: pointer;
}
</style>