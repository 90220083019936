import request from '@/api/request'

/**
 * 添加图片
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function addImg(data) {
    return request({
        url: '/image/save',
        method: 'POST',
        data
    })
}

/**
 * 获取图片列表
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function getImgList(data) {
    return request({
        url: '/image/list',
        method: 'POST',
        data
    })
}

/**
 *
 * @param id
 * @returns {AxiosPromise}
 */
export function deleteImg(id) {
    return request({
        url: '/image/delete/'+id,
        method: 'POST',
    })
}

