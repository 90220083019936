import axios from "axios";

export function getBingDailyImg() {
    const params = {
        format: 'js',
        idx: 0,
        n: 1
    };
    return axios.request({
        method: 'GET',
        baseURL: '/bingApi',
        url: '/HPImageArchive.aspx',
        params
    });
}