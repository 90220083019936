<template>
  <div class="container">
    <div class="text-control-panel">
      <div class="text-title">
        <el-input
            maxlength="48"
            show-word-limit
            v-model="title"
            placeholder="请输入标题">
        </el-input>
      </div>

      <el-button @click="handlePreview">
        预览
      </el-button>
      <el-button @click="dialogVisible = true" type="primary">
        发布
      </el-button>
    </div>

    <div class="text-write-panel">
      <div class="text-write">
        <textarea class="article-write" id="easyMDE"></textarea>
      </div>
    </div>

    <el-dialog
        width="40%"
        title="文章设置"
        :visible.sync="dialogVisible">

      <el-tabs v-model="activeName" @tab-click="handleTabClick">
        <el-tab-pane label="常规" name="first">
          <ArticleBasicInfoForm ref="articleBasicInfo" :articleTile="title"></ArticleBasicInfoForm>
        </el-tab-pane>
        <el-tab-pane label="高级" name="second">
          <ArticleMoreInfoForm ref="articleMoreInfo"></ArticleMoreInfoForm>
        </el-tab-pane>
      </el-tabs>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleDialogConfirm">发 布</el-button>
      </span>
    </el-dialog>

    <AttachmentDialog :attachmentDialogVisible.sync="attachmentDialogVisible"
                      @handleAttachmentItem="handleAttachmentItem"
    ></AttachmentDialog>

  </div>
</template>

<script>
import EasyMDE from 'easymde'
import 'easymde/dist/easymde.min.css'
import ArticleBasicInfoForm from "@/views/article/components/ArticleBasicInfoForm";
import ArticleMoreInfoForm from "@/views/article/components/ArticleMoreInfoForm";
import AttachmentDialog from "@/components/attachment";
import {addArticle, getArticleDetail} from "@/api/article/articleApi";

export default {
  name: 'ArticleWrite',
  components: {ArticleBasicInfoForm, ArticleMoreInfoForm, AttachmentDialog},
  activated() {
    this.articleId = this.$route.params.articleId;
    if (this.articleId) {
      this.fetchData(this.articleId);
    } else {
      this.initialMDE();
    }
  },
  data() {
    let self = this;
    return {
      easyMDE: null,
      articleItem: {},
      markdownContent: "",
      htmlContent: "",
      title: "",
      dialogVisible: false,
      activeName: "first",
      attachmentDialogVisible: false,
      editor: Object,
      easyMDEToolBar: [
        "bold", "italic", "heading", "|",
        "quote", "unordered-list", "ordered-list", "|",
        "code", "link", "table", "|",
        "fullscreen", "preview", "|", "guide", "|",
        {
          name: "添加附件",
          action: function customFunction(editor) {
            // Add your own code
            self.insertAttachment(editor);
          },
          className: "fa iconfont icon-div-1",
          title: "图片或附件",
        },
      ]
    }
  },
  methods: {
    fetchData(id) {
      getArticleDetail(id)
          .then((response) => {
            if (response.code === '00000000000000') {
              this.$message.success("获取文章详情成功");
              this.dealData(response.data);
              this.title = this.articleItem.title;
              this.markdownContent = this.articleItem.content;
              this.initialMDE(this.markdownContent);
            }
          })
          .catch((error) => {
            console.log(error)
          })
    },
    dealData(data) {
      this.articleItem = {
        id: data.id,
        title: data.nameCn,
        content: data.content,
        brief: data.brief,
        coverUrl: data.coverUrl,
        author: data.createdBy,
        date: data.createdTime,
        updater: data.updatedBy,
        updatedTime: data.updatedTime,
        revision: data.revision
      };
    },
    handlePreview() {
      this.$message.info("待开发...");
    },
    handleSubmit() {
      //this.$message.success("已发布");
      //this.$router.push("/");
    },
    handleTabClick() {
    },
    handleDialogConfirm() {
      this.dialogVisible = false;
      let title = this.$refs.articleBasicInfo.ruleForm.title;
      console.log(this.$refs.articleBasicInfo);
      let brief = this.$refs.articleBasicInfo.ruleForm.brief;
      let privateView = this.$refs.articleMoreInfo.form.privateView ? 1 : 0;
      let coverUrl = this.$refs.articleMoreInfo.form.coverUrl;

      let formData = {
        nameCn: title,
        brief: brief,
        content: this.markdownContent,
        privateView: privateView,
        coverUrl: coverUrl,
      }
      if (this.articleId) {
        formData.id = this.articleId;
        formData.revision = this.articleItem.revision;
      }
      this.handleAddArticle(formData);
    },
    handleAddArticle(addData) {
      addArticle(addData)
          .then((response) => {
            if (response.code === '00000000000000') {
              this.$message.success("添加文章成功");
              this.$router.push("/article");
            }
          })
          .catch((error) => {
            console.log(error)
          })
    },
    handleAttachmentItem(data) {
      let editor = this.editor;
      console.log("attachment click is :", data);
      if (data.type === "img") {
        this.drawImage(editor, data);
      } else {
        this.drawLink(editor, data);
      }
    },
    /**
     * Action for drawing a link.
     */
    drawLink(editor, data) {
      let cm = editor.codemirror;
      let stat = this.getState(cm);
      let options = editor.options;
      let url = data.url;
      if (options.promptURLs) {
        url = prompt(options.promptTexts.link, url);
        if (!url) {
          return false;
        }
        url = this.escapePromptURL(url);
      }
      let customOption = ['[' + data.name + '](', '#url#)'];
      this._replaceSelection(cm, stat.link, customOption, url);
    },
    /**
     * Action for drawing an img.
     */
    drawImage(editor, data) {
      let cm = editor.codemirror;
      let stat = this.getState(cm);
      let options = editor.options;
      let url = data.url;
      if (options.promptURLs) {
        url = prompt(options.promptTexts.image, url);
        if (!url) {
          return false;
        }
        url = this.escapePromptURL(url);
      }
      let customOption = ['![' + data.name + '](', '#url#)'];
      this._replaceSelection(cm, stat.image, customOption, url);
    },
    /**
     * Action for drawing an img.
     */
    insertAttachment(editor) {
      this.attachmentDialogVisible = true;
      this.editor = editor;
    },
    escapePromptURL(url) {
      return encodeURI(url).replace(/([\\()])/g, '\\$1');
    },
    /**
     * The state of CodeMirror at the given position.
     */
    getState(cm, pos) {
      pos = pos || cm.getCursor('start');
      let stat = cm.getTokenAt(pos);
      if (!stat.type) return {};

      let types = stat.type.split(' ');

      let ret = {},
          data, text;
      for (let i = 0; i < types.length; i++) {
        data = types[i];
        if (data === 'strong') {
          ret.bold = true;
        } else if (data === 'letiable-2') {
          text = cm.getLine(pos.line);
          if (/^\s*\d+\.\s/.test(text)) {
            ret['ordered-list'] = true;
          } else {
            ret['unordered-list'] = true;
          }
        } else if (data === 'atom') {
          ret.quote = true;
        } else if (data === 'em') {
          ret.italic = true;
        } else if (data === 'quote') {
          ret.quote = true;
        } else if (data === 'strikethrough') {
          ret.strikethrough = true;
        } else if (data === 'comment') {
          ret.code = true;
        } else if (data === 'link') {
          ret.link = true;
        } else if (data === 'tag') {
          ret.image = true;
        } else if (data.match(/^header(-[1-6])?$/)) {
          ret[data.replace('header', 'heading')] = true;
        }
      }
      return ret;
    },
    _replaceSelection(cm, active, startEnd, url) {
      if (/editor-preview-active/.test(cm.getWrapperElement().lastChild.className))
        return;

      let text;
      let start = startEnd[0];
      let end = startEnd[1];
      let startPoint = {},
          endPoint = {};
      Object.assign(startPoint, cm.getCursor('start'));
      Object.assign(endPoint, cm.getCursor('end'));
      if (url) {
        start = start.replace('#url#', url);  // url is in start for upload-image
        end = end.replace('#url#', url);
      }
      if (active) {
        text = cm.getLine(startPoint.line);
        start = text.slice(0, startPoint.ch);
        end = text.slice(startPoint.ch);
        cm.replaceRange(start + end, {
          line: startPoint.line,
          ch: 0,
        });
      } else {
        text = cm.getSelection();
        cm.replaceSelection(start + text + end);

        startPoint.ch += start.length;
        if (startPoint !== endPoint) {
          endPoint.ch += start.length;
        }
      }
      cm.setSelection(startPoint, endPoint);
      cm.focus();
    },
    initialMDE(content) {
      if (this.easyMDE) {
        this.easyMDE.toTextArea();
        this.easyMDE = null;
      }

      this.easyMDE = new EasyMDE({
        el: document.getElementById('easyMDE'),
        placeholder: '请使用 Markdown 格式书写 ;-)',
        toolbar: this.easyMDEToolBar
      });

      let MDE = this.easyMDE;

      if (content) {
        MDE.value(content);
      }
      MDE.codemirror.on("change", () => {
        this.markdownContent = MDE.value();
      })
    }
  },
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-control-panel {
  display: flex;
  width: 100%;
  margin: 12px 12px;
}

.text-title {
  padding: 0 12px 0 0;
  flex: 1 0 auto;
}

.text-write-panel {
  margin: 12px 0;
  display: flex;
  flex-direction: row;
  width: 860px;
}

.text-write {
  width: 100%;
}

.text-preview {
  width: 50%;
  margin: 0 12px 0 0;
  background: #ffffff;
  border: 1px solid #bbb;
  border-radius: 4px;
  display: none;
}

@media only screen and (min-width: 1000px) {
  .large-screen {
    display: block;
  }
}
</style>
