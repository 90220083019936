<template>
  <div class="container">
    <div class="author-wrap">
      <el-avatar :src="commentItem.avatarUrl"></el-avatar>
      <div class="author-name-wrap"><strong>{{ commentItem.userName }}</strong></div>
      <div class="comment-control-wrap">
        <el-button type="text" @click="handleReplyComment(commentItem.id)">回复</el-button>
        <el-button type="text" v-if="username === commentItem.userName"
                   @click="handleDeleteComment(commentItem.id)">
          删除
        </el-button>
      </div>

    </div>
    <div class="comment-content-wrap">
      {{ commentItem.content }}
    </div>
    <div class="comment-time-wrap">
      <span class="comment-time-span-wrap">{{ commentItem.createdTime }}</span>
    </div>

    <div class="comment-replay-wrap">
      <CommentInput :isReplay="true" v-if="showReplayInput" @handleAddReplyComment="handleAddReplyComment"></CommentInput>
    </div>

    <el-divider></el-divider>
  </div>
</template>

<script>
import {getSessionStorage, time2ReadableString} from '@/util/util'
import CommentInput from "@/components/comment/CommentInput";

export default {
  name: "CommentItem",
  components: {CommentInput},
  mounted() {
  },
  props: {
    commentData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showReplayInput: false
    }
  },
  computed: {
    commentItem() {
      let data = this.commentData;
      data.createdTime = time2ReadableString(this.commentData.createdTime);

      return data;
    },
    username() {
      let userInfoStr = getSessionStorage("userInfo");
      userInfoStr = userInfoStr ? userInfoStr : "{}";
      let userInfo = JSON.parse(userInfoStr);
      return userInfo ? userInfo.username : "";
    },
  },
  methods: {
    handleDeleteComment(id) {
      this.$EventBus.$emit('handleDeleteComment', id);
    },
    handleReplyComment() {
      this.showReplayInput = true;
    },
    handleAddReplyComment(content) {
      this.showReplayInput = false;
      const data = {
        replyCommentId: this.commentItem.id,
        commentContent: content
      }
      this.$EventBus.$emit('handleAddReplyComment', data);
    }
  }
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
}

.author-name-wrap {
  flex: 1 0 auto;
  margin-left: 4px;
}

.author-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.comment-content-wrap {
  margin-top: 6px;
}

.comment-time-span-wrap {
  font-style: normal;
  font-size: 12px;
  color: grey;
}

.comment-control-wrap {
  display: inline;
  text-align: right;
}

.comment-control-wrap:hover {
  display: block;
}

</style>