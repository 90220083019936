<template>
  <div class="container">
    <template v-for="(item) in commentDataList">
      <div class="comment-list-wrap" :key="item.id">
        <CommentItem :commentData="item"></CommentItem>
        <div class="child-comment-wrap" v-if="item.children">
          <CommentList :commentDataList="item.children"></CommentList>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import CommentItem from "@/components/comment/CommentItem";

export default {
  name: "CommentList",
  components: {CommentItem},
  props: {
    commentDataList: {
      type: Array,
      required: true
    }
  },
  methods: {
  }
}
</script>

<style scoped>
.child-comment-wrap {
  padding-left: 24px;
}
</style>