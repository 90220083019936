<template>
  <div>
    <el-table
        border
        :page-size="5"
        :total="totalNum"
        :data="infoTableData"
        style="width: 100%"
        size="small"
        row-key="id"
        :header-cell-style="{ 'text-align': 'center' }"
        v-loading="isTableLoading"
        element-loading-background="rgba(255, 255, 255, .5)"
        element-loading-text="加载中，请稍后..."
        element-loading-spinner="el-icon-loading">
      <el-table-column type="index"
                       fixed="left"
                       label="序号"
                       align="center"
                       width="50">
      </el-table-column>

      <template v-for="item in columnsData">
        <el-table-column
            :prop="item.prop"
            :label="item.label"
            :formatter="item.formatter"
            :width="item.width"
            :key="item.prop"
            :align="item.align">
        </el-table-column>
      </template>

      <el-table-column fixed="right"
                       align="center"
                       label="操作"
                       width="120">
        <template slot-scope="scope">
          <el-button @click="handleDetail(scope.row)" type="text">查看
          </el-button>
          <el-popconfirm
              @confirm="confirmDelete(scope.row)"
              title="确定删除吗？">
            <el-button
                style="margin-left: 10px"
                slot="reference"
                type="text">删除
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>

    </el-table>
  </div>
</template>

<script>
export default {
  name: "InfoTable",
  components: {},
  props: {
    tableData: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    columnsInfo: {
      type: Array,
      required: true,
    }
  },

  data() {
    return {};
  },
  computed: {
    infoTableData: {
      get() {
        return this.tableData;
      },
    },
    isTableLoading: {
      get() {
        return this.isLoading;
      },
    },
    totalNum: {
      get() {
        return this.total;
      },
    },
    columnsData: {
      get() {
        return this.columnsInfo;
      }
    },
  },
  methods: {
    handleDetail(row) {
      console.log(row);
    },
    confirmDelete(row) {
      this.$emit("handleRowDelete", row.id);
    }
  }
}
;
</script>

<style scoped>
</style>

