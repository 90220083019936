<template>
  <div class="container">
    <el-form :inline="true" :model="formData" ref="attachmentSearchForm" class="demo-form-inline">
      <el-form-item
          :prop="item.vModel"
          :key="item.vModel"
          :label="item.label"
          v-for="item in computedFormSettings">
        <el-input
            :clearable="true"
            :placeholder="item.placeholder"
            class="inputItem"
            v-if="item.type === 'textInput'"
            v-model="formData[item.vModel]"
        ></el-input>
        <el-select
            :clearable="true"
            :placeholder="item.placeholder"
            v-else-if="item.type === 'selection'"
            v-model="formData[item.vModel]">
          <el-option
              :key="item.value"
              :label="item.label"
              :value="item.value"
              v-for="item in item.options">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="info" @click="onReset">重置</el-button>
        <el-button type="primary" @click="onSubmit">查询</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "AttachmentControl",
  props: {
    formItemSettings: {
      type: Array,
      // required: true,
    }
  },
  data() {
    return {
      formData: {
        keyword: '',
        source: '',
        type: ''
      },
      formItemSettingsTest: [
        {type: "textInput", label: "关键词", vModel: "keyword", placeholder: "关键词"},
        {
          type: "selection", label: "文件来源", vModel: "source", placeholder: "文件来源",
          options: [{label: "相册", value: "0"}, {label: "文件", value: "1"},]
        },
        {
          type: "selection", label: "文件类型", vModel: "type", placeholder: "文件类型",
          options: [{label: "图片", value: "0"}, {label: "文档", value: "1"}, {label: "音视频", value: "2"},]
        },
      ]
    }
  },
  computed: {
    computedFormSettings() {
      return this.formItemSettingsTest
    }
  },
  methods: {
    onSubmit() {
      this.$emit("onAttachmentSearchSubmit")
    },
    onReset() {
      this.$refs['attachmentSearchForm'].resetFields()
    },
  },
}
</script>

<style scoped>

</style>