<template>
  <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
    <el-form-item label="文章标题" prop="title">
      <el-input type="text" placeholder="请输入标题" v-model="ruleForm.title"></el-input>
    </el-form-item>
    <el-form-item label="文章摘要" prop="brief">
      <el-input type="textarea" placeholder="如不填写，会从文章自动截取" v-model="ruleForm.brief"></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: "ArticleBasicInfoForm",
  props: {
    articleTile: {
      type: String,
    }
  },
  data() {
    return {
      ruleForm: {
        title: this.articleTile,
        brief: ""
      },
      rules: {
        name: [
          {required: true, message: '请输入标题', trigger: 'blur'},
          {max: 48, message: '长度不超过48', trigger: 'blur'}
        ],
      },
    }
  }
}
</script>

<style scoped>

</style>