<template>
  <div class="container">
    <div class="form-wrap">
      <el-form :model="addForm" status-icon :rules="rules" ref="addForm" class="demo-formData">
        <template v-for="item in formItemData">
          <el-form-item
              :key="item.id"
              :label="item.label"
              :prop="item.prop"
              :rules="rules[item.rule]">
            <div class="form-input-wrap">
              <el-input size="small" v-if="item.type === 'input'" type="text" :placeholder="item.placeholder"
                        v-model="addForm[item.model]"
                        autocomplete="off"></el-input>
              <el-date-picker
                  v-else-if="item.type === 'date'"
                  v-model="addForm[item.model]"
                  type="date"
                  placeholder="选择日期">
              </el-date-picker>
            </div>
          </el-form-item>
        </template>

        <el-form-item>
          <el-upload
              class="upload-demo"
              ref="imgUpload"
              action="none"
              :http-request="uploadMethods"
              :on-change="handleFileUploadChange"
              :on-progress="uploadProgress"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              list-type="picture"
              :limit="1"
              :auto-upload="true">
            <el-button slot="trigger" size="small">选取文件</el-button>
            <div slot="tip" class="el-upload__tip">只能上传不超过10MB的图片文件</div>
          </el-upload>
        </el-form-item>

        <el-form-item>
          <el-button size="small" @click="handleCancel('addForm')">取消</el-button>
          <el-button :loading="isLoading" size="small" type="primary" @click="submitForm('addForm')">提交</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div ref="ribbons-container" v-show="showRibbons" class="ribbons-container"></div>
  </div>
</template>

<script>
import {addImg} from '@/api/album/imgApi'
import {uploadFile} from '@/api/file/fileApi'
import {generateRibbons} from '@/util/ribbons'

export default {
  name: "ImgManageAddForm",
  data() {
    return {
      addForm: {
        imgUri: '',
        imgName: '',
        imgDesc: '',
        imgDate: '',
      },
      rules: {
        imgName: [
          {required: true, message: '内容不能为空', trigger: 'blur'},
        ],
        imgDesc: [
          {required: true, message: '内容不能为空', trigger: 'blur'},
        ],
        imgDate: [
          {required: true, message: '内容不能为空', trigger: 'change'},
        ],
      },
      formItemData: [
        {
          id: "1",
          label: '图片名称',
          prop: 'imgName',
          rule: 'imgName',
          model: 'imgName',
          placeholder: "请输入图片名称",
          type: 'input'
        },
        {
          id: "2",
          label: '图片描述',
          prop: 'imgDesc',
          rule: 'imgDesc',
          model: 'imgDesc',
          placeholder: "请输入图片描述",
          type: 'input'
        },
        {
          id: "3",
          label: '图片日期',
          prop: 'imgDate',
          rule: 'imgDate',
          model: 'imgDate',
          placeholder: "请输入图片日期",
          type: 'date'
        },
      ],
      isLoading: false,
      showRibbons: false,
    };
  },
  mounted() {
  },
  methods: {
    handleFileUploadChange() {
    },
    handleRemove() {
    },
    handlePreview(file) {
      console.log(file);
    },
    submitUpload() {
      this.$refs["imgUpload"].submit();
    },
    uploadMethods(params) {
      let formData = new FormData();
      formData.append("file", params.file);
      uploadFile(formData).then((response) => {
        if (response.code === '00000000000000') {
          this.$message.success("恭喜~图片文件上传成功啦");
          this.addForm.imgUri = response.data.uri;
        } else {
          this.$message.error(response.msg);
        }
      }).catch((error) => {
        console.log(error);
        this.$message.error("上传图片到COS失败");
      })
    },
    uploadProgress(event) {
      console.log("event", event);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.addForm.imgUri === "") {
            this.$message.warning("没有要上传的图片")
            return false;
          }
          //保存图片记录
          this.isLoading = true;
          addImg(this.getSubmitData(this.addForm))
              .then((response) => {
                if (response.code === '00000000000000') {
                  this.$message.success("添加图片成功啦~");
                  this.isLoading = false;
                  this.resetForm("addForm");
                  this.$router.back();
                }
              })
              .catch((error) => {
                this.isLoading = false;
                console.log(error)
              })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleCancel(formName) {
      this.resetForm(formName);
      this.$router.back();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    getSubmitData(data) {
      return {
        imgName: data.imgName,
        imgDesc: data.imgDesc,
        imgDate: data.imgDate,
        imgUri: data.imgUri
      }
    },
    showRibbonsContainer() {
      this.showRibbons = !this.showRibbons;
      let container = this.$refs["ribbons-container"];
      this.$nextTick(() => {
        if (container.children[0]) {
          container.removeChild(container.children[0]);
        }
        if (this.showRibbons) {
          generateRibbons(container);
        }
      });
    }
  }
}
</script>
<style scoped>
.container {
  background: #ffffff;
  margin: 12px;
}

.form-wrap {
  margin: 12px;
}

.form-input-wrap {
  width: 100%;
}

.ribbons-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 999;
}
</style>