<template>
  <el-form :model="addForm" ref="addForm" label-width="100px" class="demo-ruleForm">
    <template v-for="item in formItemData">
      <el-form-item
          :key="item.id"
          :label="item.label"
          :prop="item.prop"
          :rules="rules[item.rule]">
        <div class="form-input-wrap">
          <el-input v-if="item.type === 'input'" type="text" :placeholder="item.placeholder"
                    v-model="addForm[item.model]"
                    autocomplete="off"></el-input>
          <el-date-picker
              v-else-if="item.type === 'date'"
              v-model="addForm[item.model]"
              type="date"
              placeholder="选择日期">
          </el-date-picker>
          <el-color-picker v-else v-model="addForm.color"></el-color-picker>
        </div>
      </el-form-item>
    </template>

    <el-form-item>
      <el-button size="small" type="primary" @click="submitForm('addForm')">提交</el-button>
      <el-button size="small" @click="handleCancel('addForm')">取消</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import {addActivity} from "@/api/timeline/activityApi";

export default {
  name: 'ActivityAddForm',
  data() {
    return {
      addForm: {
        content: '',
        timestamp: '',
        color: '#dc143c'
      },
      rules: {
        content: [
          {required: true, message: '内容不能为空', trigger: 'blur'},
        ],
        timestamp: [
          {required: true, message: '时间不能为空', trigger: 'change'},
        ],
        color: [
          {required: true, message: '不能为空'},
        ],
      },
      formItemData: [
        {id: "1", label: '内容', prop: 'content', rule: 'content', model: 'content', placeholder: "请输入内容", type: 'input'},
        {
          id: "2",
          label: '日期',
          prop: 'timestamp',
          rule: 'timestamp',
          model: 'timestamp',
          placeholder: "请输入时间",
          type: 'date'
        },
        {id: "3", label: '颜色', prop: 'color', rule: 'color', model: 'color', placeholder: "请选择颜色", type: 'color'},
      ]
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          addActivity(this.getSubmitData(this.addForm))
              .then(response => {
                if (response.code === '00000000000000') {
                  this.$emit("handleActivityAdd");
                  this.$message.success("添加活动成功");
                  this.resetForm("addForm");
                  this.$router.back();
                }
              })
              .catch((error) => {
                console.log(error)
              })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleCancel(formName) {
      this.resetForm(formName);
      this.$router.back();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    getSubmitData(data) {
      return {
        activityName: data.content,
        activityDate: data.timestamp,
        activityDesc: data.content,
        activityColor: data.color
      }
    }
  }
}
</script>
<style scoped>
.form-input-wrap {
  width: 360px;
}
</style>