import Timeline from "@/views/timeline";
import ArticleWrite from "@/views/article/articleWrite";
import Album from "@/views/album";
import Welcome from "@/views/welcome/Welcome";
import Img from "@/views/img";

export default [
    {path: '/welcome', name: 'welcome', component: Welcome,meta: {requiresRole: ["user", "admin"]}},
    {path: '/album', name: 'album', component: Album, meta: {requiresRole: ["user", "admin"]}},
    {path: '/img', name: 'img', component: Img, meta: {requiresRole: ["user", "admin"]}},
    {path: '/timeline', name: 'timeline', component: Timeline, meta: {requiresRole: ["user", "admin"]}},
    {path: '/article/write', name: 'articleWrite', component: ArticleWrite, meta: {requiresRole: ["user", "admin"]}},
]