<template>
  <div class="container">
    <h1>{{ username }} </h1>
    <h3>{{ typewriterStr }}</h3>
    <el-button type="danger" icon="el-icon-chat-line-round" circle @click="handleChat"></el-button>
  </div>
</template>

<script>
import {getSessionStorage} from "@/util/util";

export default {
  name: 'Welcome',
  data() {
    return {
      typewriterStr: '',
      i: 0,
      timer: 0,
      str: '你好呀，\n欢迎',
    }
  },
  mounted() {
    this.typing();
  },
  props: {
    msg: String
  },
  computed: {
    username() {
      let userInfoStr = getSessionStorage("userInfo");
      userInfoStr = userInfoStr ? userInfoStr : "{}";
      let userInfo = JSON.parse(userInfoStr);
      return userInfo ? userInfo.username : "";
    },
  },
  methods: {
    typing() {
      if (this.i <= this.str.length) {
        this.typewriterStr = this.str.slice(0, this.i++);
        this.timer = setTimeout(() => {
          this.typing();
        }, 200)
      } else {
        clearTimeout(this.timer);
      }
    },
    handleChat() {
      this.$router.push("/chat");
    }
  }
}
</script>

<style scoped>
.container {
  text-align: center;
}

.welcome-img-wrap {
  width: 100px;
  height: 100px;
}
</style>
