import request from '@/api/request'

/**
 * 添加用户
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function addUser(data) {
    return request({
        url: '/user/save',
        method: 'POST',
        data
    })
}

/**
 * 获取用户列表
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function getUserList(data) {
    return request({
        url: '/user/list',
        method: 'POST',
        data
    })
}



