<template>
  <div class="main" style="height: 100%;display: flex; flex-direction:column">
    <Header :menus="menus" class="navBarWrap" :class="{fixed:navBarFixed}" style="flex: 0 0 auto;"></Header>
    <div class="content" style="flex: 1 0 auto;">
      <transition name="move" mode="out-in">
        <keep-alive>
          <router-view :key="$route.path"/>
        </keep-alive>
      </transition>
    </div>
    <Footer style="flex: 0 0 auto;"></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import {getSessionStorage} from "@/util/util";

export default {
  name: 'Layout',
  computed: {
    username() {
      let userInfoStr = getSessionStorage("userInfo");
      userInfoStr = userInfoStr ? userInfoStr : "{}";
      let userInfo = JSON.parse(userInfoStr);
      return userInfo ? userInfo.username : "";
    },
    menus() {
      return this.getMenus();
    }
  },
  data() {
    return {
      minHeight: 0,
      navBarFixed: false,
      collapse: false
    };
  },
  components: {
    Header, Footer,
  },
  methods: {
    watchScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      this.navBarFixed = scrollTop > 50;
    },
    getMenus() {
      let menusLogin = [
        {id: 1, name: "主页", index: "/welcome"},
        {id: 5, name: "相册", index: "/album"},
        {id: 2, name: "文章", index: "/article"},
        {id: 6, name: "写作", index: "/article/write"},
      ];
      let menusGuest = [
        {id: 1, name: "主页", index: "/welcome"},
        {id: 2, name: "文章", index: "/article"},
        {id: 3, name: "关于", index: "/about"}]
      return this.username ? menusLogin : menusGuest;
    },
  },
  mounted() {
    let that = this;
    window.addEventListener('scroll', that.watchScroll);
  },
}
</script>

<style scoped>
.content {
  margin-top: 48px;
  background-color: #f9f9f9;
  padding: 12px 0;
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
}

.navBarWrap {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
}

.navBarWrap.fixed {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  opacity: 0.98;
  box-shadow: 0 5px 5px #D3D3D3;
}

.header {
  flex: 0 0 auto;
}

.footer {
  flex: 0 0 auto;
}
</style>