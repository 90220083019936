<template>
  <div class="container">
    <div class="qrcode-img-wrap">
      <div @click="getQRCodeImg()">
        <img ref="qrcode-img" style="object-fit:contain;" :src="qrCodeImgResponse"/>
      </div>
      <div class="qrcode-text-wrap">
        <div class="qrcode-confirm-icon-wrap" v-if="showScanOk">
          <i class="el-icon-check qrcode-confirm-icon"></i>
        </div>

        <span class="qrcode-text">{{ qrCodeTips }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import {getQRCodeState, getQRCode} from '@/api/login/loginApi'
import {generateUUID, setSessionStorage} from '@/util/util'

export default {
  data() {
    return {
      qrCodeImgResponse: Object,
      qrCodeTips: '扫描二维码登录',
      showScanOk: false,
      timer: null
    };
  },
  computed: {
    requestId() {
      return generateUUID();
    }
  },
  mounted() {
    this.getQRCodeImg();
    this.timer = setInterval(() => {
      this.queryQRCodeState(this.requestId);
    }, 2000);
    this.$once('hook:beforeDestroy', () => {
      clearInterval(this.timer);
    })
  },
  deactivated() {
    clearInterval(this.timer);
  },
  methods: {
    getQRCodeImg() {
      const data = {
        requestId: this.requestId
      };
      getQRCode(data).then((response) => {
        this.qrCodeImgResponse = 'data:image/png;base64,' + btoa(
            new Uint8Array(response.data)
                .reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
      }).catch((error) => {
        this.$message.error("获取二维码失败");
        console.log(error);
      });
    },
    queryQRCodeState(requestId) {
      getQRCodeState(requestId).then((response) => {
        if (response.code === '00000000000000') {
          if (response.data.state === "1") {
            //扫描成功
            this.showScanOk = true;
            this.qrCodeTips = '扫描成功，请在客户端确认';
          }
          if (response.data.state === "2") {
            //登录成功
            this.showScanOk = false;
            this.qrCodeTips = '登录成功，跳转中...';
            clearInterval(this.timer);

            let userInfoJson = JSON.parse(response.data.userInfo);

            let userInfo = {
              username: userInfoJson.username,
              role: userInfoJson.roleName,
              avatar: userInfoJson.avatar
            };

            let token = userInfoJson.token;
            this.$store.commit('handleUserInfo', userInfo);
            this.$store.commit('handleToken', token);

            setSessionStorage("userInfo", userInfo);
            setSessionStorage("token", token);
            this.$message.success("登录成功");
            this.$router.push("/");
          }
          if (response.data.state === "3") {
            //登录成功
            this.qrCodeTips = '登录失败，请重试';
            clearInterval(this.timer);
          }
          if (response.data.state === "4") {
            //登录成功
            this.qrCodeTips = '已超时';
            clearInterval(this.timer);
          }
        }
      }).catch((error) => {
        clearInterval(this.timer);
        console.log(error)
      })
    }
  }
}
</script>
<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrcode-img-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrcode-wrap {
  width: 100%;

}

.qrcode-text-wrap {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.qrcode-text {
}

.qrcode-confirm-icon-wrap {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: limegreen;
  margin-right: 8px;
  position: relative;
}

.qrcode-confirm-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

</style>