import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        userInfo: {},
        token: '',
        activeManageTab: '',
        manageTabs: [],
        msgList: [],
    },

    getters: {
        userInfo: (state) => state.userInfo,
        token: (state) => state.token,
        activeManageTab: (state => state.activeManageTab),
        manageTabs: (state => state.manageTabs),
        msgList: (state => state.msgList)
    },

    mutations: {
        handleUserInfo(state, data) {
            state.userInfo = data;
        },
        handleToken(state, data) {
            state.token = data;
        },
        setActiveManageTab(state, data) {
            state.activeManageTab = data;
        },
        setManageTabs(state, data) {
            state.manageTabs = data;
        },
        setMsgList(state,data) {
            state.msgList = data;
        }
    },
    actions: {},
    modules: {}
})

export default store
