<template>
  <div class="container">
    <div class="tips-wrap">
      <h1>404 这里什么也没有...</h1>
    </div>
    <div class="tips-wrap">
      <el-button type="primary" @click="handleBack2Home">带我回首页</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
    handleBack2Home() {
      this.$router.push("/");
    }
  }
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tips-wrap {

}
</style>
