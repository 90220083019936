<template>
  <div class="container">
    <div class="table-control-wrap">
      <div class="search-input-wrap">
        <el-input
            clearable
            size="small"
            placeholder="请输入名称"
            @keyup.enter.native="handleQuery"
            v-model="requestForm.name">
        </el-input>
      </div>
      <el-button type="primary" size="small" @click="handleQuery">搜索</el-button>
      <el-button type="success" size="small" @click="handleAdd">新增</el-button>
    </div>
    <InfoTable
        @handleRowDelete="handleRowDelete"
        :columnsInfo="columnsData"
        :tableData="InfoDataTable"
        :isLoading="isLoading"
        :total="total">
    </InfoTable>
    <div class="pagination-wrap">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageForm.pageNum"
          :page-sizes="[5, 10, 20, 50]"
          :page-size="pageForm.pageSize"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import InfoTable from "@/views/manage/components/InfoTable";
import {getUserList} from "@/api/user/userApi";

export default {
  name: "UserManage",
  components: {InfoTable},
  activated() {
    this.fetchData(this.getRequestData());
  },
  data() {
    return {
      pageForm: {
        pageNum: 1,
        pageSize: 10,
      },
      requestForm: {},
      total: 0,
      InfoDataAll: [],
      InfoDataTable: [],
      isLoading: false,
      columnsData: [
        {prop: "id", label: "id", width: "150", align: "center",},
        {prop: "name", label: "名称", width: "150", align: "center"},
        {prop: "nickName", label: "昵称", width: "200", align: "center"},
        {prop: "phone", label: "电话", width: "200", align: "center"},
        {prop: "email", label: "邮箱", width: "200", align: "center"},
        {prop: "birthday", label: "生日", width: "200", align: "center"},
        {prop: "profile", label: "简介", width: "400", align: "left",},
        {prop: "avatar", label: "头像", width: "150", align: "center",},
        {prop: "createdBy", label: "创建人ID", width: "150", align: "center",},
        {prop: "createTime", label: "创建时间", width: "200", align: "center",},
      ],
    };
  },
  methods: {
    handleSizeChange(val) {
      this.pageForm.pageNum = 1;
      this.pageForm.pageSize = val;
      this.fetchData(this.getRequestData());
    },
    handleCurrentChange(val) {
      this.pageForm.pageNum = val;
      this.fetchData(this.getRequestData());
    },
    handleQuery() {

    },
    handleAdd() {
      this.$bus.$emit("handleUserAdd");
    },
    handleRowDelete() {
      this.$message.warning("暂不支持删除用户");
    },
    fetchData(requestData) {
      this.isLoading = true;
      getUserList(requestData)
          .then((response) => {
            if (response.code === '00000000000000') {
              this.$message.success("获取用户列表成功");
              this.dealData(response.data);
              this.isLoading = false;
            }
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          })
    },
    dealData(data) {
      this.total = data.total;
      this.InfoDataAll = [];
      let list = data.list;
      list.forEach(item => {
        let tmp = {
          id: item.id,
          name: item.userName,
          nickName: item.userNickname,
          phone: item.userPhone,
          email: item.userEmail,
          birthday: item.userBirthday,
          profile: item.userProfile,
          avatar: item.userAvatar,
          createdBy: item.createdBy,
          createTime: item.createTime
        }
        this.InfoDataAll.push(tmp);
      });
      this.InfoDataTable = this.InfoDataAll;
    },
    getRequestData() {
      return {
        page: this.pageForm.pageNum,
        size: this.pageForm.pageSize
      };
    }
  }
}
;
</script>

<style scoped>
.container {
  background: #ffffff;
  margin: 12px;
}

.search-input-wrap {
  margin: 10px;
  display: inline-block
}

.pagination-wrap {
  padding: 10px 20px;
  text-align: right;
}
</style>

