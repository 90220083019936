<template>
  <div class="container">
    <div class="show-login-btn-wrap">
      <el-button size="small" icon="el-icon-user-solid" circle @click="showLogin = !showLogin"></el-button>
    </div>
    <el-image
        class="background_img"
        :src="imgData ? imgData.imgUrl : ''"
        fit="cover">
    </el-image>
    <div class="login-wrap">
      <transition name="slide-fade">
        <el-card class="login-card" v-if="showLogin">
          <div class="qrcode-icon-wrap" @click="showQRCodeLogin=!showQRCodeLogin">
            <svg class="icon-font">
              <use xlink:href="#icon-erweima"></use>
            </svg>
          </div>
          <div class="qrcode-login-wrap" v-if="showQRCodeLogin">
            <QRCodeLogin></QRCodeLogin>
          </div>

          <div class="login-form-wrap" v-else>
            <el-tabs v-model="activeName" @tab-click="handleTabClick">
              <el-tab-pane label="账号密码登录" name="accountFormLogin">
                <AccountLoginForm></AccountLoginForm>
              </el-tab-pane>
              <el-tab-pane label="手机验证码登录" name="smsFormLogin">
                <SMSLoginForm></SMSLoginForm>
              </el-tab-pane>
            </el-tabs>
            <div class="other-login-wrap">
            </div>
          </div>

        </el-card>
      </transition>

    </div>

    <div class="copyright-wrap">
      © 2022 crkyl.cn | <a class="register-number-wrap" target="_blank" rel="noopener noreferrer"
                           href="https://beian.miit.gov.cn/">黔ICP备18011172号-2</a>
    </div>

    <div class="copyright-wrap" style="width:300px;margin:0 auto; padding:20px 0;">
      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=52032102000660"
         style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
        <img src="http://www.beian.gov.cn/img/ghs.png" style="float:left;"/>
        <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#D3D3D3;">贵公网安备
          52032102000660号</p></a>
    </div>

    <div class="img-copyright-wrap" :copyright-info="imgData ? imgData.imgCopyright : ''">
      <i class="el-icon-info"/>
    </div>

    <div class="show-snow-btn-wrap" @click="showSnowContainer">
      &#127876;
    </div>

    <div ref="snow-container" v-show="showSnow" class="snow-container"></div>
  </div>
</template>

<script>
import AccountLoginForm from "@/views/login/components/AccountLoginForm";
import SMSLoginForm from "@/views/login/components/SMSLoginForm";
import QRCodeLogin from "@/views/login/components/QRCodeLogin";

import {getBingDailyImg} from "@/api/thirdPart/bingApi"
import {getDailyImg} from "@/api/login/loginApi"
import {formatDate} from "@/util/util"
import {generateSnow} from "@/util/snow"

export default {
  name: 'Login',
  data() {
    return {
      showLogin: false,
      showSnow: false,
      activeName: "smsFormLogin",
      imgData: null,
      showQRCodeLogin: false
    }
  },
  mounted() {
    console.log("%cSince 2021-12-11", "color:#FFC0CB;font-size:2em");
    //获取服务器每日图片
    getDailyImg(this.getRequestData()).then((response) => {
      if (response.code === '00000000000000') {
        if (response.data) {
          this.imgData = this.getCleanedResponseData(response.data);
        }
        if (!this.imgData) {
          //获取bing每日图片
          getBingDailyImg().then((response) => {
            let data = response.data.images;
            if (data) {
              this.imgData = this.getCleanedBingResponseData(data);
              if (!this.imgData) {
                this.setDefaultImg();
              }
            }
          }).catch((error) => {
            console.log(error);
            this.setDefaultImg();
          });
        }
      }
    }).catch((error) => {
      console.log(error);
      this.setDefaultImg();
    });
  },
  components: {
    AccountLoginForm, SMSLoginForm, QRCodeLogin
  },
  methods: {
    handleBack2Home() {
      this.$router.push("/");
    },
    handleTabClick() {

    },
    getRequestData() {
      let today = new Date();
      let date = formatDate(today, 'yyyyMMdd');
      return {
        queryDate: date
      }
    },
    getCleanedResponseData(data) {
      return {
        imgUrl: data.imgUrl,
        imgCopyright: data.imgCopyright
      };
    },
    getCleanedBingResponseData(data) {
      let imgItem = data[0];
      let index = imgItem.copyright.indexOf("(");
      let imgDesc = imgItem.copyright.substring(0, index);
      let copyright = imgItem.copyright.substring(index);
      return {
        startDate: imgItem.startdate,
        endDate: imgItem.enddate,
        name: imgItem.title,
        type: 2,
        desc: imgDesc,
        weight: 1,
        imgUrl: 'https://cn.bing.com/' + imgItem.url,
        imgCopyright: copyright
      };
    },
    setDefaultImg() {
      this.imgData = {imgUrl: "https://crkyl-1251696585.cos.ap-guangzhou.myqcloud.com/sun.jpg"};
    },
    showSnowContainer() {
      this.showSnow = !this.showSnow;
      let container = this.$refs["snow-container"];
      this.$nextTick(() => {
        if (container.children[0]) {
          container.removeChild(container.children[0]);
        }
        if (this.showSnow) {
          generateSnow(container);
        }
      });
    }
  },
}
</script>

<style scoped>

.container {
  min-height: 100%;
  display: flex;
  justify-content: center;
}

.background_img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -100;
}

.show-login-btn-wrap {
  position: absolute;
  padding: 8px;
  top: 0;
  right: 0;
}

.login-wrap {
  width: 400px;
  position: absolute;
  top: 120px;
  background: transparent;
}

.login-card {
  padding: 20px;
  opacity: 1.0;
  overflow: hidden;
}

.icon-font {
  width: 2em;
  height: 2em;
  fill: currentColor;
  overflow: hidden;
}

.qrcode-icon-wrap {
  position: absolute;
  top: 5px;
  right: 5px;
}

.copyright-wrap {
  font-size: 14px;
  padding: 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: #D3D3D3;
  text-align: center;
}

.register-number-wrap {
  color: #D3D3D3;
  font-size: 14px;
}

.img-copyright-wrap {
  font-size: 10px;
  padding: 6px;
  position: absolute;
  bottom: 0;
  left: 0;
  color: #D3D3D3;
}

.img-copyright-wrap:hover::after {
  content: attr(copyright-info);
  display: inline-block;
  margin-left: 4px;
  position: absolute;
  top: -10px;
  width: 500px;
}

.snow-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 999;
}

.show-snow-btn-wrap {
  position: absolute;
  cursor: pointer;
  margin: 8px;
  bottom: 0;
  right: 0;
}

.slide-fade-enter-active {
  transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-leave-active {
  transition: all .5s ease;
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active for below version 2.1.8 */
{
  transform: translateX(10px);
  opacity: 0;
}

</style>
