<template>
  <div class="chat-header-container">
    <span style="font-weight: bold">{{chatName}}</span>
  </div>
</template>

<script>

export default {
  name: 'ChatHeader',
  components: {
  },
  props: {},
  data() {
    return {
      chatName: '聊天',
      onlineNumber:2
    };
  },
  computed: {},
  created() {
  },
  mounted() {
  },
  methods: {

  }
};
</script>

<style scoped>
.chat-header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #DCDCDC;
  min-height: 32px;
}

</style>
