<template>
  <div class="container">
    <div class="form-wrap">
      <el-form :model="addForm" status-icon :rules="rules" ref="addForm" class="demo-formData">
        <template v-for="item in formItemData">
          <el-form-item
              :key="item.id"
              :label="item.label"
              :prop="item.prop"
              :rules="rules[item.rule]">
            <div class="form-input-wrap">
              <el-input size="small" v-if="item.type === 'input'" type="text" :placeholder="item.placeholder"
                        v-model="addForm[item.model]"
                        autocomplete="off"></el-input>
              <el-date-picker
                  size="small"
                  v-else-if="item.type === 'date'"
                  v-model="addForm[item.model]"
                  type="date"
                  placeholder="选择日期">
              </el-date-picker>

              <el-select
                  v-else-if="item.type === 'select'"
                  clearable
                  size="small"
                  ref="belongBusinessModuleSelect"
                  v-model="addForm[item.model]"
                  :placeholder="item.placeholder"
                  style="width: 100%">
                <el-option
                    v-for="element in selectListData[item.selections]"
                    :key="element.id"
                    :label="element.name"
                    :value="element.value"
                />
              </el-select>

            </div>
          </el-form-item>
        </template>


        <el-form-item>
          <el-button size="small" @click="handleCancel('addForm')">取消</el-button>
          <el-button size="small" type="primary" @click="submitForm('addForm')">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {addDailyImg} from '@/api/dayImg/dayImgApi'
import {formatDate} from '@/util/util'

export default {
  name: "DayImgAddForm",
  data() {
    return {
      addForm: {
        startDate: "",
        endDate: "",
        dayName: "",
        dayType: "",
        dayWeight: "",
        dayDesc: " ",
        imgUrl: "",
        imgCopyright: ""
      },
      selectListData: {
        dataTypeList: [
          {id: "1", name: "节假日", value: "1"},
          {id: "2", name: "自定义", value: "2"},
        ],
      },
      rules: {
        startDate: [
          {required: true, message: '内容不能为空', trigger: 'blur'},
        ],
        endDate: [
          {required: true, message: '内容不能为空', trigger: 'blur'},
        ],
        dayName: [
          {required: true, message: '内容不能为空', trigger: 'change'},
        ],
        imgUrl: [
          {required: true, message: '内容不能为空', trigger: 'change'},
        ],
        dayWeight: [
          {required: true, message: '内容不能为空', trigger: 'change'},
        ],
      },
      formItemData: [
        {
          id: "1",
          label: '开始日期',
          prop: 'startDate',
          rule: 'startDate',
          model: 'startDate',
          placeholder: "请选择开始日期",
          type: 'date'
        },
        {
          id: "2",
          label: '结束日期',
          prop: 'endDate',
          rule: 'endDate',
          model: 'endDate',
          placeholder: "请选择结束日期",
          type: 'date'
        },
        {
          id: "3",
          label: '日期名称',
          prop: 'dayName',
          rule: 'dayName',
          model: 'dayName',
          placeholder: "请输入日期名称",
          type: 'input'
        },
        {
          id: "4",
          label: '日期类型',
          prop: 'dayType',
          rule: 'dayType',
          model: 'dayType',
          placeholder: "请选择日期类型",
          type: 'select',
          selections: 'dataTypeList'
        },
        {
          id: "5",
          label: '日期权重',
          prop: 'dayWeight',
          rule: 'dayWeight',
          model: 'dayWeight',
          placeholder: "请输入日期权重",
          type: 'input'
        },
        {
          id: "6",
          label: '日期描述',
          prop: 'dayDesc',
          rule: 'dayDesc',
          model: 'dayDesc',
          placeholder: "请输入日期描述",
          type: 'input'
        },
        {
          id: "7",
          label: '图片url',
          prop: 'imgUrl',
          rule: 'imgUrl',
          model: 'imgUrl',
          placeholder: "请输入图片url",
          type: 'input'
        },
        {
          id: "8",
          label: '图片版权信息',
          prop: 'imgCopyright',
          rule: 'imgCopyright',
          model: 'imgCopyright',
          placeholder: "请输入图片版权信息",
          type: 'input'
        },
      ],

    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          addDailyImg(this.getSubmitData(this.addForm))
              .then((response) => {
                if (response.code === '00000000000000') {
                  this.$message.success("添加每日图片成功");
                  this.resetForm("addForm");
                  this.$router.back();
                }
              })
              .catch((error) => {
                console.log(error)
              })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleCancel(formName) {
      this.resetForm(formName);
      this.$router.back();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    getSubmitData(data) {
      return {
        startDate: formatDate(data.startDate, 'yyyyMMdd'),
        endDate: formatDate(data.endDate, 'yyyyMMdd'),
        dayName: data.dayName,
        dayType: data.dayType,
        dayWeight: data.dayWeight,
        dayDesc: data.dayDesc,
        imgUrl: data.imgUrl,
        imgCopyright: data.imgCopyright
      }
    }
  }
}
</script>
<style scoped>
.container {
  background: #ffffff;
  margin: 12px;
}

.form-wrap {
  margin: 12px;
}

.form-input-wrap {
  width: 360px;
}
</style>