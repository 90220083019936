<template>
  <div class="container">
    <ImgList :imgList="imgList"></ImgList>
    <div class="add-btn-wrap">
      <el-button type="primary" icon="el-icon-plus" circle @click="handleAddImg"></el-button>
    </div>
    <ImgAddDialog  :imgAddDialogVisible.sync="imgAddDialogVisible"
                  @handleSubmit="handleImgAddSubmit"
    ></ImgAddDialog>
  </div>
</template>

<script>
import {addImg, getImgList} from "@/api/album/imgApi";
import ImgList from "@/views/img/components/ImgList";
import ImgAddDialog from "@/views/img/components/ImgAddDialog";

export default {
  name: "index.vue",
  components: {ImgList, ImgAddDialog},
  activated() {
    this.albumId = this.$route.params.albumId;
    this.fetchData(this.getRequestData());
  },
  data() {
    return {
      pageForm: {
        pageNum: 1,
        pageSize: 100,
      },
      albumId: '',
      imgList: [],
      isAdmin: true,
      imgAddDialogVisible: false
    }
  },
  methods: {
    fetchData(requestData) {
      getImgList(requestData)
          .then((response) => {
            if (response.code === '00000000000000') {
              this.$message.success("获取图片列表成功");
              this.dealData(response.data);
            }
          })
          .catch((error) => {
            console.log(error)
          })
    },
    dealData(data) {
      this.imgList = [];
      let list = data.list;
      list.forEach(item => {
        let tmp = {
          id: item.id,
          name: item.nameCn,
          url: item.url+"?imageMogr2/format/webp",
          desc: item.brief
        }
        this.imgList.push(tmp);
      })
    },
    getRequestData() {
      let dto = {albumId: this.albumId};
      return {
        page: this.pageForm.pageNum,
        size: this.pageForm.pageSize,
        dto: dto
      };
    },
    handleAddImg() {
      this.imgAddDialogVisible = true;
    },
    handleImgAddSubmit(data) {
      data.albumId = this.albumId;
      addImg(data)
          .then((response) => {
            if (response.code === '00000000000000') {
              this.$message.success("添加图片成功啦~");
              this.imgAddDialogVisible = false;

              this.fetchData(this.getRequestData());
            }
          })
          .catch((error) => {
            console.log(error)
          })
    }
  }
}
</script>

<style scoped>
.add-btn-wrap {
  position: absolute;
  margin: 64px 15px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, .32);
  bottom: 0;
  right: 0;
  z-index: 100;
  transition: all 0.5s;
}

.add-btn-wrap:hover {
  transform: translateY(-3px);
}
</style>