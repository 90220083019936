<template>
  <div class="footer-wrap">
    <span>后台管理 2021</span>
  </div>
</template>

<script>

export default {
  name: "ManageFooter",
}
</script>

<style scoped>
.footer-wrap {
  flex: 0 0 auto;
  text-align: center;
  background: grey;
  padding: 4px;
}
</style>