import request from '@/api/request'

/**
 * 添加文章
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function addAttachment(data) {
    return request({
        url: '/attachment/save',
        method: 'POST',
        data
    })
}

/**
 * 获取文章列表
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function getAttachmentList(data) {
    return request({
        url: '/attachment/list',
        method: 'POST',
        data
    })
}

/**
 *
 * @param id
 * @returns {AxiosPromise}
 */
export function deleteAttachment(id) {
    return request({
        url: '/attachment/delete/'+id,
        method: 'POST',
    })
}

/**
 *
 * @param id
 * @returns {AxiosPromise}
 */
export function getAttachmentDetail(id) {
    return request({
        url: '/attachment/detail/'+id,
        method: 'GET',
    })
}
