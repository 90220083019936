<template>
  <el-form :model="formData" status-icon :rules="rules" ref="formData" class="demo-formData">
    <el-form-item prop="account">
      <el-input placeholder="请输入账号" type="text" v-model="formData.account" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input placeholder="请输入密码" type="password" v-model="formData.password" autocomplete="off"
                @keyup.enter.native="submitForm('formData')"
                show-password></el-input>
    </el-form-item>
    <div class="captcha-wrap" v-if="showCaptcha">
      <div class="captcha-text-wrap">
        <el-form-item prop="captcha">
          <el-input placeholder="请输入验证码" v-model="formData.captcha"></el-input>
        </el-form-item>
      </div>
      <div class="captcha-img-wrap">
        <el-form-item>
          <div @click="getCaptcha()">
            <img ref="captcha-img" style="object-fit:contain;" :src="captchaImgResponse"/>
          </div>
        </el-form-item>
      </div>
    </div>

    <div class="submit-wrap">
      <el-button style="width: 100%" type="primary" @click="submitForm('formData')">登录</el-button>
    </div>

  </el-form>
</template>
<script>
import {accountLogin, getCaptcha} from '@/api/login/loginApi'
import {setSessionStorage} from '@/util/util'
import Base64 from 'crypto-js/enc-base64'
import sha256 from 'crypto-js/sha256';

export default {
  data() {
    return {
      showCaptcha: false,
      captchaImgResponse: Object,
      formData: {
        role: 'user',
        account: '',
        password: '',
        captcha: ''
      },
      rules: {
        account: [
          {required: true, message: '请输入账号', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'}
        ],
        captcha: [
          {required: true, message: '请输入验证码', trigger: 'blur'}
        ]
      }
    };
  },
  mounted() {
    this.getCaptcha();
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          accountLogin(this.getSubmitData(this.formData))
              .then((response) => {
                if (response.code === '00000000000000') {
                  let userInfo = {
                    username: response.data.username,
                    role: response.data.roleName,
                    avatar: response.data.avatar
                  };
                  let token = response.data.token;
                  this.$store.commit('handleUserInfo', userInfo);
                  this.$store.commit('handleToken', token);

                  setSessionStorage("userInfo", userInfo);
                  setSessionStorage("token", token);
                  this.$message.success("登录成功");
                  this.$router.push("/");
                } else if (response.code === 'USER005') {
                  this.showCaptcha = true;
                }
              })
              .catch((error) => {
                console.log(error);
              });
        } else {
          this.$message.warning("请检查后提交");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    getSubmitData(data) {
      let cryptPwd = Base64.stringify(sha256(data.password));
      let submitData =  {
        username: data.account,
        password: cryptPwd,
      }
      if (this.showCaptcha) {
        submitData.captcha = data.captcha;
      }
      return submitData;
    },
    getCaptcha() {
      getCaptcha().then((response) => {
        this.captchaImgResponse = 'data:image/png;base64,' + btoa(
            new Uint8Array(response.data)
                .reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
      }).catch((error) => {
        this.$message.error("获取验证码失败");
        console.log(error);
      });
    }
  }
}
</script>
<style scoped>
.captcha-wrap {
  display: flex;
}

.captcha-text-wrap {
  width: 80%;
  flex: 0 1 auto;
}

.captcha-img-wrap {
  margin-left: 12px;
  flex: 0 0 auto;
  width: 30%;
}

.submit-wrap {
  width: 100%;
}
</style>