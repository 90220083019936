<template>
  <div class="main-wrap">
    <div class="article-wrap markdown-body">
      <el-image
          style="width: 100%; height: 300px"
          :src="articleItem.coverUrl"
          fit="cover"></el-image>

      <div class="article-title">
        <h1>{{ articleItem.title }}</h1>
      </div>

      <div class="article-author">
        {{ articleItem.author }} |
        <span style="color: darkgray">{{ articleItem.date }}</span>
      </div>

      <div class="article-desc statement">
        <span>{{ articleItem.brief }}</span>
      </div>

      <div class="article-content">
        <p v-html="articleItem.content"></p>
      </div>

      <div class="copyright-info-wrap">
        <span>{{ articleItem.updater }}最后修改在{{ articleItem.updatedTime }}</span>
        <!-- Trigger -->
      </div>

      <div class="edit-btn-wrap">
        <el-button type="primary" icon="el-icon-edit" circle @click="handleEditArticle"></el-button>
      </div>

      <el-divider></el-divider>

      <Comment :dataId="articleId"></Comment>

    </div>

  </div>
</template>

<script>

import {getArticleDetail} from "@/api/article/articleApi";
import 'github-markdown-css/github-markdown.css'
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';
import Showdown from 'showdown';
import showdownToc from 'showdown-toc';
import Comment from '@/components/comment/index'

export default {
  name: 'ArticleDetail',
  mounted() {
    hljs.highlightAll();
  },
  updated() {
    hljs.highlightAll();
  },

  activated() {
    this.articleId = this.$route.query.articleId;
    this.fetchData(this.articleId);
  },
  components: {Comment},
  data() {
    return {
      total: 5,
      articleId: '',
      htmlContent: '',
      articleItem: {}
    }
  },
  computed: {},
  methods: {
    fetchData(id) {
      getArticleDetail(id)
          .then((response) => {
            if (response.code === '00000000000000') {
              this.$message.success("获取文章详情成功");
              this.dealData(response.data);
            }
          })
          .catch((error) => {
            console.log(error)
          })
    },
    dealData(data) {
      const toc = [
        {anchor: 'header-1', level: 1, text: 'header 1'},
        {anchor: 'header-2', level: 2, text: 'header 2'},
        {anchor: 'header-3', level: 3, text: 'header 3'},];
      const converter = new Showdown.Converter({extensions: [showdownToc({toc})]});
      this.htmlContent = converter.makeHtml(data.content);

      this.articleItem = {
        id: data.id,
        title: data.nameCn,
        content: this.htmlContent,
        brief: data.brief,
        coverUrl: data.coverUrl,
        viewCount: 1953,
        author: data.createdBy,
        date: data.createdTime,
        updater: data.updatedBy,
        updatedTime: data.updatedTime,
      };
    },
    handleEditArticle() {
      this.$router.push({name: 'articleWrite', params: {articleId: this.articleId}})
    }
  }
}
</script>

<style scoped>
.main-wrap {
  display: flex;
  width: 100%;
  align-items: center;
}

.article-wrap {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.statement {
  width: 50%;
  border-left: 3px solid #008000;
  padding: 20px;
  background-color: #EBEEF5;
  margin-top: 20px;
}

.markdown-body {
  box-sizing: border-box;
  min-width: 200px;
  max-width: 980px;
  margin: 0 auto;
  padding: 45px;
}

@media (max-width: 767px) {
  .markdown-body {
    padding: 15px;
  }
}

.edit-btn-wrap {
  position: absolute;
  margin: 64px 15px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, .32);
  bottom: 0;
  right: 0;
  z-index: 100;
  transition: all 0.5s;
}

.edit-btn-wrap:hover {
  transform: translateY(-3px);
}
</style>
