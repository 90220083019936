<template>
  <div class="container">
    <template v-for="(item) in imgItemList">
      <el-card class="card-warp" :key="item.id">
        <el-image class="card-img" fit="cover" :src="item.url" :preview-src-list="imgUrlList"></el-image>
        <span class="card-span-font">{{ item.desc }}</span>
      </el-card>
    </template>
    <el-backtop></el-backtop>
  </div>
</template>

<script>

export default {
  name: 'ImgList',
  data() {
    return {}
  },
  mounted() {
  },
  props: {
    imgList: {
      type: Array, required: true
    }
  },
  computed: {
    imgItemList: {
      get() {
        return this.imgList;
      }
    },
    imgUrlList: {
      get() {
        let list = [];
        this.imgList.forEach(item => {
          list.push(item.url);
        });
        return list;
      }
    }
  },
  methods: {

  }
}
</script>

<style scoped>
.container {
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  display: flex;
}

.card-warp {
  margin: 12px;
  width: 360px;
  height: 270px;
  cursor: url("https://crkyl-1251696585.cos.ap-guangzhou.myqcloud.com/mm.cur"), default;
  transition: all 1.5s;
}

.card-img {
  width: 100%;
  height: 220px;
}

.card-img :hover {
  transform: scale(1.02);
  cursor: url("https://crkyl-1251696585.cos.ap-guangzhou.myqcloud.com/mm.cur"), default;
}

.card-span-font {
  color: #808080;
  font-size: 14px;
}

</style>
