<template>
  <div class="container">
    <div class="article-wrap">
      <template v-for="(item,index) in articleList">
        <ArticleItem
            @handleArticleDetail="handleArticleDetail"
            :key="index"
            :articleItem="item">
        </ArticleItem>
      </template>
    </div>
    <div class="pagination-wrap">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageForm.pageNum"
          :page-size="pageForm.pageSize"
          :page-sizes="[5, 10, 20, 50]"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import ArticleItem from "@/views/article/components/ArticleItem";
import {getArticleList} from "@/api/article/articleApi";
export default {
  name: 'Article',
  activated() {
    this.fetchData(this.getRequestData());
  },
  components: {
    ArticleItem,
  },
  computed: {
    username() {
      let userInfo = this.$store.state.userInfo;
      return userInfo.account ? userInfo.account : "";
    },
  },
  data() {
    return {
      total: 5,
      pageForm: {
        pageNum: 1,
        pageSize: 10,
      },
      articleList: [],
    }
  },
  methods: {
    handleBack2Home() {
      this.$router.push("/");
    },
    handleSizeChange(val) {
      this.pageForm.pageSize = val;
      this.pageForm.pageNum = 1;
      this.fetchData(this.getRequestData());
    },
    handleCurrentChange(val) {
      this.pageForm.pageNum = val;
      this.fetchData(this.getRequestData());
    },
    handleArticleDetail(id) {
      this.$router.push({path: '/article/detail', query: {articleId: id}});
    },
    fetchData(requestData) {
      getArticleList(requestData)
          .then((response) => {
            if (response.code === '00000000000000') {
              this.$message.success("获取文章列表成功");
              this.dealData(response.data);
            }
          })
          .catch((error) => {
            console.log(error)
          })
    },
    dealData(data) {
      this.articleList = [];
      this.total = data.total;
      let list = data.list;
      list.forEach(item => {
        let tmp = {
          id: item.id,
          title: item.nameCn,
          content: item.content,
          brief: item.brief,
          coverUrl: item.coverUrl+"?imageMogr2/format/webp",
          viewCount: 1953,
          author: item.updatedBy,
          date: item.updatedTime,
        }
        this.articleList.push(tmp);
      })
    },
    getRequestData() {
      return {
        page: this.pageForm.pageNum,
        size: this.pageForm.pageSize,
      };
    },
  }
}
</script>

<style scoped>
.container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.article-wrap {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%;
}

.pagination-wrap {
  flex: 0 0 auto;
  padding: 10px 20px;
  text-align: left;
}
</style>
