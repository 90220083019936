import axios from 'axios'
import {Message, MessageBox} from 'element-ui'
import store from '../store'
import {getSessionStorage} from '@/util/util'
import router from "@/router";

const TOKEN_KEY = 'token'

// create an axios instance
const service = axios.create({
    baseURL: '/api', // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 50 * 1000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent
        let token = getSessionStorage(TOKEN_KEY);
        if (store.getters.token && token) {
            // let each request carry token
            // ['X-Token'] is a custom headers key
            // please modify it according to the actual situation
            config.headers['Authorization'] = token;
        }
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data

        // if the custom code is not 00000000000000, it is judged as an error.
        // USER005 tell user need captcha to login
        if (res.code !== '00000000000000' && res.code !== ('USER005')) {
            Message({
                message: res.msg || 'Error',
                type: 'error',
                duration: 2 * 1000
            })

            // 401: Illegal token; 50012: Other clients logged in; 50014: Token expired;
            if (res.code === '401' || res.code === 50012 || res.code === 50014) {
                // to re-login
                MessageBox.confirm('没有权限访问，您可以选择取消以留在当前页，或者选择重新登录', '权限错误', {
                    confirmButtonText: '重新登录',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    router.push("/login").then(() => {
                        location.reload()
                    })
                })
            }
            return Promise.reject(new Error(res.msg || 'Error'));
        } else {
            return res
        }
    },
    error => {
        console.log('err' + error) // for debug
        Message({
            message: error.message,
            type: 'error',
            duration: 2 * 1000
        })
        return Promise.reject(error)
    }
)

export default service

//不拦截response
export const download = (url, method, data) => {
    return axios.request({
        method: method,
        baseURL: '/api',
        url: url,
        responseType: 'arraybuffer',
        data
    });
}