import request from '@/api/request'

/**
 * 添加相册
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function addAlbum(data) {
    return request({
        url: '/album/save',
        method: 'POST',
        data
    })
}

/**
 * 获取相册列表
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function getAlbumList(data) {
    return request({
        url: '/album/list',
        method: 'POST',
        data
    })
}

/**
 * 删除相册
 * 
 * @param id
 * @returns {AxiosPromise}
 */
export function deleteAlbum(id) {
    return request({
        url: '/album/delete/'+id,
        method: 'POST',
    })
}

