<template>
  <div class="container">
    <div class="article-item-wrap" @click="handleArticleDetail">
      <el-image class="article-img-wrap"
                :src="articleItem.coverUrl"
                fit="cover">
      </el-image>
      <div class="article-info-wrap">
        <div class="article-title-wrap">
          <strong>{{ articleItem.title }}</strong>
        </div>
        <div class="article-author-wrap">
          <span class="article-author">{{ articleItem.author }} | {{ articleItem.date }}</span>
        </div>
      </div>

      <div class="mask">
        <div class="article-desc-wrap"><span class="article-desc">{{ articleItem.brief }}</span></div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    articleItem: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {};
  },
  methods: {
    handleArticleDetail() {
      this.$emit("handleArticleDetail",this.articleItem.id);
    }
  }
}
</script>

<style scoped>
.container {
  display: flex;
}

.article-item-wrap {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 280px;
  height: 300px;
  margin: 12px 10px 12px 10px;
  cursor: pointer;
  transition: all 0.5s;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.article-item-wrap:hover {
  transform: scale(1.02);
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
}

.article-img-wrap {
  flex: 0 0 auto;
  height: 180px;
  width: 100%;
  transition: all 0.5s;
}

.article-info-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 12px 0;
  flex: 0 1 auto;
}

.article-title-wrap {
  margin-left: 6px;
  height: 70%;
  flex: 0 0 auto;
}

.article-author-wrap {
  margin-left: 6px;
  flex: 0 1 auto;
}

.article-author {
  color: grey;
  font-size: medium;
}

.mask {
  top: 0;
  width: 280px;
  height: 180px;
  text-align: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
}

.mask:hover {
  transition: all 1s;
  width: 280px;
  height: 180px;
  opacity: 1;
}

.article-desc-wrap {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.article-desc {
  color: white;
}

</style>