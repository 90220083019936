<template>
  <div class="container">
    <div class="album-item-wrap" @click="handleAlbumDetail(albumItem.id)">
      <el-image class="album-img-wrap"
                :src="albumItem.coverUrl"
                fit="cover">
      </el-image>
      <div class="album-info-wrap">
        <div class="album-title-wrap">
          <strong>{{ albumItem.name }}</strong>
        </div>
        <div class="album-private-wrap">
          <span class="album-private"><b>{{albumItem.imgCount}} 张</b></span>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AlbumItem",
  props: {
    albumItem: {
      type: Object,
      required: true,
    }
  },
  computed: {
    privateViewTips: function () {
      // `this` 指向 vm 实例
      return this.albumItem.privateView === 0 ? "公开相册" : "仅自己可见"
    }
  },
  data() {
    return {};
  },
  methods: {
    handleAlbumDetail(id) {
      this.$emit("handleAlbumDetail",id);
    }
  }
}
</script>

<style scoped>
.container {
  display: flex;
}

.album-item-wrap {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 280px;
  height: 250px;
  margin: 12px 10px 12px 10px;
  cursor: pointer;
  transition: all 0.5s;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.album-item-wrap:hover {
  transform: translateY(-8px);
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
}

.album-img-wrap {
  flex: 0 0 auto;
  height: 180px;
  width: 100%;
  transition: all 0.5s;
}

.album-info-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 12px 6px;
  flex: 0 1 auto;
}

.album-title-wrap {
  height: 100%;
  flex: 0 0 auto;
}

.album-private-wrap {
  flex: 0 1 auto;
  margin: -12px 0 -12px 0;;
}

.album-private {
  color: grey;
  font-size: medium;
}
</style>