<template>
  <div class="container">
    <el-dialog
        :append-to-body="true"
        width="80%"
        title="图片和附件"
        top="10vh"
        :visible.sync="dialogVisible">
      <AttachmentControl @onAttachmentSearchSubmit="onAttachmentSearchSubmit"></AttachmentControl>
      <el-upload
          class="upload-demo"
          ref="imgUpload"
          action="none"
          :http-request="uploadMethods"
          :on-change="handleFileUploadChange"
          :on-progress="uploadProgress"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          list-type="picture"
          :show-file-list="false"
          :limit="100"
          :auto-upload="true">
        <el-button slot="trigger" size="small">选取文件</el-button>
      </el-upload>
      <el-divider></el-divider>
      <AttachmentList :attachmentList="attachmentList" @handleAttachmentClick="handleAttachmentClick"></AttachmentList>

      <div class="pagination-wrap">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageForm.pageNum"
            :page-size="pageForm.pageSize"
            :total="total"
            layout="total,prev,pager,next">
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import AttachmentControl from "@/components/attachment/AttachmentControl";
import AttachmentList from "@/components/attachment/AttachmentList";
import {uploadFile} from "@/api/file/fileApi";
import {getAttachmentList, addAttachment} from "@/api/attachment/attachmentApi";

export default {
  name: "AttachmentDialog",
  mounted() {
    this.fetchData(this.getRequestData());
  },
  props: {
    attachmentDialogVisible: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    dialogVisible: {
      get: function () {
        return this.attachmentDialogVisible;
      },
      set: function (newVal) {
        this.$emit('update:attachmentDialogVisible', newVal);
      }
    }
  },
  components: {
    AttachmentControl, AttachmentList
  },
  data() {
    return {
      total: 0,
      pageForm: {
        pageNum: 1,
        pageSize: 14,
      },
      attachmentList: [],

    }
  }
  ,
  methods: {
    onAttachmentSearchSubmit() {
      this.fetchData(this.getRequestData());
    },
    handleSizeChange(val) {
      this.pageForm.pageSize = val;
      this.pageForm.pageNum = 1;
    },
    handleCurrentChange(val) {
      this.pageForm.pageNum = val;
    },
    handleAttachmentClick(data) {
      this.$emit("handleAttachmentItem", data);
      this.dialogVisible = false;
    },
    fetchData(requestData) {
      getAttachmentList(requestData)
          .then((response) => {
            if (response.code === '00000000000000') {
              this.$message.success("获取附件列表成功");
              this.dealData(response.data);
            }
          })
          .catch((error) => {
            console.log(error)
          })
    },
    dealData(data) {
      this.attachmentList = [];
      this.total = data.total;
      let list = data.list;
      list.forEach(item => {
        let tmp = {
          id: item.id,
          name: item.nameCn,
          url: item.url,
        }
        this.attachmentList.push(tmp);
      })
    },
    getRequestData() {
      return {
        page: this.pageForm.pageNum,
        size: this.pageForm.pageSize,
      };
    },
    handleFileUploadChange() {
    },
    handleRemove() {
    },
    handlePreview(file) {
      console.log(file);
    },
    uploadMethods(params) {
      let formData = new FormData();
      formData.append("file", params.file);
      uploadFile(formData).then((response) => {
        if (response.code === '00000000000000') {
          this.$message.success("恭喜~文件上传成功啦");
          const url = response.data.uri;
          const name = params.file.name;
          const addData = {
            nameCn: name,
            url: url,
            revision: '1'
          };
          this.handleAddAttachment(addData);
        } else {
          this.$message.error(response.msg);
        }
      }).catch((error) => {
        console.log(error);
        this.$message.error("上传文件到COS失败");
      })
    },
    uploadProgress(event) {
      console.log("event", event);
    },
    handleAddAttachment(addData) {
      addAttachment(addData)
          .then((response) => {
            if (response.code === '00000000000000') {
              this.$message.success("添加附件成功");
              this.fetchData(this.getRequestData());
            }
          })
          .catch((error) => {
            console.log(error)
          })
    },
  }
}
</script>

<style scoped>
.container {

}

.pagination-wrap {
  flex: 0 0 auto;
  padding: 10px 20px;
  text-align: left;
}
</style>