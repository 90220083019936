<template>
  <div class="main-wrap">
    <ManageHeader class="header-wrap"></ManageHeader>

    <div class="content-wrap">
      <el-tabs v-model="editableTabsValue" type="card" :closable="editableTabs.length > 1" @tab-remove="removeTab"
               @tab-click="handleClick">
        <template v-for="(item) in editableTabs">
          <el-tab-pane :label="item.label" :name="item.name" :key="item.name"></el-tab-pane>
        </template>
      </el-tabs>
      <transition name="move" mode="out-in">
        <keep-alive>
          <router-view :key="$route.path"/>
        </keep-alive>
      </transition>
    </div>

    <ManageFooter></ManageFooter>
  </div>
</template>
<script>
import ManageHeader from '@/views/manage/components/ManageHeader'
import ManageFooter from "@/views/manage/components/ManageFooter";

export default {
  name: 'Manage',
  data() {
    return {
      editableTabsValue: '/manage/dashboard',
      editableTabs: [
        {label: "首页", name: "/manage/dashboard"},
        {label: "用户管理", name: "/manage/user"},
        {label: "照片管理", name: "/manage/img"},
        {label: "活动管理", name: "/manage/activity"},
        {label: "每日图片管理", name: "/manage/dayimg"},
      ]
    };
  },
  components: {
    ManageHeader, ManageFooter
  },
  watch: {
    $route(to) {
      this.editableTabsValue = to.path;
      this.$store.commit('setActiveManageTab', to.path)
    }
  },
  created() {
  },
  beforeDestroy() {
  },
  mounted() {
    let storeTabs = this.$store.getters.manageTabs;
    if (storeTabs.length === 0) {
      this.$store.commit('setManageTabs', this.editableTabs);
    }
    this.editableTabs = this.$store.getters.manageTabs;

    if (this.$store.getters.activeManageTab !== '') {
      this.editableTabsValue = this.$store.getters.activeManageTab;
    }
    this.$router.push(this.editableTabsValue);

    this.$bus.$on("handleImgAdd", () => {
      let tabItem = {label: "新增照片", name: "/manage/img/add"};
      this.addTab(tabItem);
    });
    this.$bus.$on("handleActivityAdd", () => {
      let tabItem = {label: "新增活动", name: "/manage/activity/add"};
      this.addTab(tabItem);
    });
    this.$bus.$on("handleUserAdd", () => {
      let tabItem = {label: "新增用户", name: "/manage/user/add"};
      this.addTab(tabItem);
    });
    this.$bus.$on("handleDayImgAdd", () => {
      let tabItem = {label: "新增每日图片", name: "/manage/dayimg/add"};
      this.addTab(tabItem);
    });
  },
  methods: {
    handleClick(tab) {
      this.$router.push(tab.name);
    },
    addTab(item) {
      let newTabName = item.name;
      let isDuplicate = false;
      this.editableTabs.forEach(tab => {
        if (tab.name === item.name) {
          console.log("重复添加", item);
          isDuplicate = true;
        }
      })
      if (!isDuplicate) {
        this.editableTabs.push(item);
      }
      this.$store.commit('setManageTabs', this.editableTabs);
      this.editableTabsValue = newTabName;
      this.$router.push(newTabName);
    },
    removeTab(targetName) {
      let tabs = this.editableTabs;
      let activeName = this.editableTabsValue;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }

      this.editableTabsValue = activeName;
      this.editableTabs = tabs.filter(tab => tab.name !== targetName);

      this.$store.commit('setManageTabs', this.editableTabs);
      this.$router.push(activeName);
    },
  }
};
</script>
<style scoped>
.main-wrap {
  height: 100%;
  display: flex;
  background: #f9f9f9;
  flex-direction: column
}

.header-wrap {
  flex: 0 1 auto;
  width: 100%;
  height: 48px;
}

.content-wrap {
  margin: 20px;
  background: #ffffff;
  flex: 1 0 auto;
}

</style>