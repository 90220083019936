<template>
  <el-form :model="formData" status-icon :rules="rules" ref="formData" class="demo-formData">
    <el-form-item prop="phoneNumber">
      <el-input ref="phoneNumber" placeholder="请输入手机号" type="text" v-model="formData.phoneNumber"></el-input>
    </el-form-item>
    <div class="sms-wrap">
      <div class="sms-code-wrap">
        <el-form-item prop="smsCode">
          <el-input ref="smsCode" placeholder="请输入验证码" type="text" v-model="formData.smsCode"
                    @keyup.enter.native="submitForm('formData')"></el-input>
        </el-form-item>
      </div>
      <div class="sms-get-code-wrap">
        <el-form-item>
          <el-button :disabled="smsBtnDisable"
                     type="primary"
                     @click="handleGetSMS()">
            {{ smsTips }}
          </el-button>
        </el-form-item>
      </div>
    </div>
    <div class="submit-wrap">
      <el-button style="width: 100%" type="primary" @click="submitForm('formData')">登录</el-button>
    </div>
  </el-form>
</template>
<script>
import {smsLogin, getSms} from "@/api/login/loginApi";
import {setSessionStorage, isValidPhone} from "@/util/util";

export default {
  data() {
    //手机号码校验
    const validateMobile = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号码不能为空'))
      }
      if (isValidPhone(value)) {
        return callback()
      } else {
        return callback(new Error('请输入正确的手机号码'))
      }
    }
    return {
      smsTips: '获取验证码',
      smsBtnDisable: false,
      formData: {
        phoneNumber: '',
        smsCode: '',
      },
      rules: {
        phoneNumber: [
          {validator: validateMobile, trigger: 'blur'}
        ],
        smsCode: [
          {required: true, message: '请输入验证码', trigger: 'blur'}
        ],
      }
    };
  },
  watch: {},
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          smsLogin(this.getSubmitData(this.formData))
              .then((response) => {
                if (response.code === '00000000000000') {
                  let userInfo = {
                    username: response.data.username,
                    role: response.data.roleName,
                    avatar: response.data.avatar
                  };
                  let token = response.data.token;
                  this.$store.commit('handleUserInfo', userInfo);
                  this.$store.commit('handleToken', token);

                  setSessionStorage("userInfo", userInfo);
                  setSessionStorage("token", token);
                  this.$message.success("登录成功");
                  this.$router.push("/");
                }
              })
              .catch((error) => {
                console.log(error);
              });
        } else {
          this.$message.warning("请检查后提交");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleGetSMS() {
      if (!isValidPhone(this.formData.phoneNumber)) {
        this.$message.warning("请输入正确的手机号");
        return;
      }
      this.smsBtnDisable = true;
      setTimeout(() => {
        this.doLoop(60);
      }, 500);

      getSms(this.getSubmitData(this.formData))
          .then((response) => {
            if (response.code === '00000000000000') {
              this.$message.success("验证码发送成功");
              //聚焦到验证码输入框
              this.$nextTick(() => {
                this.$refs.smsCode.focus()
              })
            }
          })
          .catch((error) => {
            console.log(error);
          });
    },
    doLoop(seconds) {
      seconds = seconds ? seconds : 60;
      this.smsTips = seconds + 's后获取';
      let countdown = setInterval(() => {
        if (seconds > 0) {
          this.smsTips = seconds + 's后获取';
          --seconds;
        } else {
          this.smsTips = '获取验证码';
          this.smsBtnDisable = false;
          clearInterval(countdown);
        }
      }, 1000)
    },
    getSubmitData(data) {
      return {
        userPhone: data.phoneNumber,
        smsCode: data.smsCode
      }
    }
  }
}
</script>
<style scoped>
.sms-wrap {
  display: flex;
  align-items: center;
}

.sms-code-wrap {
  flex: 1 1 auto;
}

.sms-get-code-wrap {
  margin-left: 12px;
  flex: 1 0 auto;
}

.submit-wrap {
  width: 100%;
}
</style>