import Vue from 'vue'
import VueRouter from 'vue-router'

import Layout from "../views/layout/index"
import NotFound from "@/views/notFound/404"
import Article from "@/views/article/index"
import About from "@/views/about/index"
import ArticleDetail from "@/views/article/articleDetail";
import Login from '@/views/login/index'
import requiresRoleRouter from "@/router/requiresRoleRouter";
import Manage from "@/views/manage";
import DashBoard from "@/views/manage/dashboard";
import ImgManage from "@/views/manage/imgManage";
import ImgManageAddForm from "@/views/manage/imgManage/ImgAddForm";
import ActivityManage from "@/views/manage/activityManage";
import ActivityAddForm from "@/views/manage/activityManage/ActivityAddForm";
import UserManage from "@/views/manage/userManage";
import UserAddFrom from "@/views/manage/userManage/UserAddFrom";
import DailyImgManage from "@/views/manage/dayImgManage";
import DailyImgAddForm from "@/views/manage/dayImgManage/DayImgAddForm";

import Chat from "@/views/chat/index"

const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace

VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject)
    }
    return originalPush.call(this, location).catch((err) => err)
}

VueRouter.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject)
    }
    return originalReplace.call(this, location).catch((err) => err)
}
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'layout',
        component: Layout,
        redirect: '/welcome',
        children: [
            {path: '/article', name: 'article', component: Article},
            {path: '/about', name: 'about', component: About},
            {path: '/article/detail', name: 'articleDetail', component: ArticleDetail},
            {path: '/chat', name: 'chat', component: Chat},
            ...requiresRoleRouter
        ],
    },
    {
        path: '/manage',
        name: 'manage',
        component: Manage,
        meta: {requiresRole: ["admin"]},
        redirect: '/manage/dashboard',
        children: [
            {path: '/manage/dashboard', name: 'dashboard', component: DashBoard, meta: {requiresRole: ["admin"]}},
            {path: '/manage/img', name: 'imgManage', component: ImgManage, meta: {requiresRole: ["admin"]}},
            {
                path: '/manage/img/add',
                name: 'imgManageAddForm',
                component: ImgManageAddForm,
                meta: {requiresRole: ["admin"]}
            },
            {
                path: '/manage/activity',
                name: 'activityManage',
                component: ActivityManage,
                meta: {requiresRole: ["admin"]}
            },
            {
                path: '/manage/activity/add',
                name: 'activityManageAddForm',
                component: ActivityAddForm,
                meta: {requiresRole: ["admin"]}
            },
            {path: '/manage/user', name: 'userManage', component: UserManage, meta: {requiresRole: ["admin"]}},
            {
                path: '/manage/user/add',
                name: 'userManageAddForm',
                component: UserAddFrom,
                meta: {requiresRole: ["admin"]}
            },
            {path: '/manage/dayimg', name: 'dayImgManage', component: DailyImgManage, meta: {requiresRole: ["admin"]}},
            {
                path: '/manage/dayimg/add',
                name: 'dayImgMangeAddForm',
                component: DailyImgAddForm,
                meta: {requiresRole: ["admin"]}
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '*',
        component: NotFound
    }
]

const router = new VueRouter({
    routes // (缩写) 相当于 routes: routes
})

export default router
